/* global L: true isIframe: true */
import { PowerMapLeafletProvider } from '../../../power-map/providers/leaflet/power-map.leaflet-provider';
import '../../../../helpers/is-iframe/is-iframe';
import './power-map-vehicles.leaflet-provider.scss';

class PowerMapVehiclesLeafletProvider extends PowerMapLeafletProvider {
  constructor(context, $element, $ngRedux, $scope, $http, urlApi) {
    super(context, $element, $ngRedux, $scope, $http, urlApi);

    this.__golfleetBehavior = $ngRedux.connect(behavior =>
      Object({
        __modules: behavior.session.modules,
      }),
    )(this);

    this.intervalFullscreen = null;
    this.msgInterval = null;
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();
  }

  $onDestroy() {
    super.$onDestroy();
  }
  /* */

  /* Public */
  enterFullscreen() {
    super.enterFullscreen();
    L.Control.MsgInterval = L.Control.extend({
      onAdd: () => {
        const div = L.DomUtil.create('div');
        div.innerHTML = 'Carregamento automático...';
        div.classList.add('msg-interval');
        return div;
      },
    });

    L.control.msgInterval = opts => new L.Control.MsgInterval(opts);
    this.msgInterval = L.control.msgInterval({ position: 'bottomright' });
    this.msgInterval.addTo(this.mapLayer);

    this.intervalFullscreen = setInterval(
      () => this.$scope.$emit('actionFullscreen'),
      3 * 60 * 1000, // 180000 = 3 min (min * secs * miliseconds)
    );

    this.$scope.$emit('onEnterFullscreen');
  }

  exitFullscreen() {
    super.exitFullscreen();
    this.msgInterval.remove();
    clearInterval(this.intervalFullscreen);
    this.$scope.$emit('onExitFullscreen');
  }

  requestGeocoding(address) {
    this.$http({
      url: `${this.urlApi}/GeoCoding/Post`,
      method: 'POST',
      data: {
        request: { street: address },
      },
    }).then(
      success => {
        if (success.status && success.status !== 200) return;
        if (success.data.data) {
          const { x: lat, y: lng } = success.data.data;

          if (this._clickToLatLngMarker) {
            this.mapLayer.removeLayer(this._clickToLatLngMarker);
          }

          const markerPointElement = document.querySelector('#marker-point');
          if (markerPointElement && !markerPointElement.hasAttribute('active')) {
            this._toggleNearBySearch();
          }

          this._clickToLatLngMarker = L.marker([lat, lng]);
          this._clickToLatLngMarker.addTo(this.mapLayer);
          this._searchNearBy(lat, lng);
          this.mapLayer.setView([lat, lng], 18, { animate: true });
        }
      },
      () => {},
    );
  }

  addMessageInfo(message) {
    L.Control.MsgInterval = L.Control.extend({
      onAdd: () => {
        const div = L.DomUtil.create('div');
        div.innerHTML = message;
        div.classList.add('msg-interval-map');
        return div;
      },
    });

    L.control.msgInterval = opts => new L.Control.MsgInterval(opts);
    const msgInfo = L.control.msgInterval({ position: 'bottomright' });
    msgInfo.addTo(this.mapLayer);
    return msgInfo;
  }

  removeMessageInfo(element) {
    element.remove();
  }
  /* */

  /* Private */
  _createMarkerIcon({
    color,
    icon,
    apelido: label,
    ignicao: ignition,
    direcao: direction,
    eventoAmbulancia: ambulanceEvent,
    indicadorTemperatura: temperatureIndicator,
    temperatura: temperature,
  }) {
    const AMBULANCE_EVENT_ICON = {
      0: '',
      1: ' &#xe050',
      2: ' &#xe99d',
      3: ' &#xe050 &#xe99d',
    };

    if (icon == 'lock_open') {
      label = label.concat(' (PRÉ-BLOQUEIO)');
    }

    if (icon == 'lock') {
      label = label.concat(' (BLOQUEADO)');
    }

    const labelFormatted = label.concat(AMBULANCE_EVENT_ICON[ambulanceEvent || 0]);

    let temperatureColor = '#A8A8A8';

    switch (temperatureIndicator) {
      case 0:
        temperatureColor = '#5CAAFF';
        break;
      case 1:
        temperatureColor = '#FFF';
        break;
      case 2:
        temperatureColor = '#FF4F63';
        break;
      default:
        break;
    }

    const createIcon = iconString => {
      if (!iconString) return '';
      return `
        <div style="color: #fff;cursor: pointer;transform: rotate(-${direction}deg);">
          <i class="material-icons" style="cursor: pointer;">
            ${iconString}
          </i>
        </div>
      `;
    };

    return L.divIcon({
      className: 'leaflet-marker-icon',
      iconSize: [20, 20],
      html: `
        <div style="margin: -10px 0px 0px -10px; z-index: 0; position: position: relative;">
        <div style="display: flex; align-items: center; font-family: Ubuntu, 'MaterialIcons'; color: rgb(255, 255, 255); padding: 4px 8px; font-size: 12px; border-radius: 2px; background-color: rgba(0, 0, 0, 0.75); position: absolute; top: -20px; left: 36px; white-space: pre;">
          <span> ${ignition ? label : labelFormatted}</span>
          ${
            !this._hasModule('FUNC_SENSOR_TEMPERATURA') ||
            temperature === null ||
            typeof temperature === 'undefined'
              ? ''
              : `
          <span style="display: flex; align-items: center; color: ${temperatureColor}"><i style="font-size: 20px;" class="material-icons">gs_temp</i> ${temperature}°C</span>
            `
          }
          </div>
          <div style="width:40px; height:40px; transform: rotate(${direction}deg);">
            <svg xmlns="https://www.w3.org/2000/svg" width="40" height="60" style="margin-top:-20px;">
              <foreignObject x="0" y="0" width="40" height="60" style="">
                <div style="display:flex;justify-content:center;align-items: center;width: 100%;height: 100%;flex-direction: column;">
                  <i class="material-icons" style="color:${
                    color || 'var(--primary-color)'
                  };cursor: pointer;font-size: 24px;margin-bottom:1px; margin-top: -4px; visibility: ${
        ignition ? 'visible' : 'hidden'
      };">
                    navigation
                  </i>
                  <div style="background:${
                    color || 'var(--primary-color)'
                  };border-radius: 100%;display:flex;justify-content:center;align-items: center;height: 32px;width: 32px;">
                    ${createIcon(icon)}
                  </div>
                </div>
              </foreignObject>
            </svg>
          </div>
        </div>
        `,
    });
  }

  _createPointOfInterestMarkerIcon(label, color) {
    return L.divIcon({
      className: 'leaflet-marker-icon',
      iconSize: [20, 20],
      html: `
        <div style="margin: -10px 0px 0px -10px; z-index: 0; position: absolute;">
          <div style="width:40px; height:40px;">
            <svg xmlns="https://www.w3.org/2000/svg" width="40" height="40">
              <path d="M 19 31 C 19 32.7 16.3 34 13 34 C 9.7 34 7 32.7 7 31 C 7 29.3 9.7 28 13 28 C 16.3 28 19 29.3 19 31 Z" fill="#000" fill-opacity=".2"></path>
              <path d="M 13 0 C 9.5 0 6.3 1.3 3.8 3.8 C 1.4 7.8 0 9.4 0 12.8 C 0 16.3 1.4 19.5 3.8 21.9 L 13 31 L 22.2 21.9 C 24.6 19.5 25.9 16.3 25.9 12.8 C 25.9 9.4 24.6 6.1 22.1 3.8 C 19.7 1.3 16.5 0 13 0 Z" fill="#fff"></path>
              <path d="M 13 2.2 C 6 2.2 2.3 7.2 2.1 12.8 C 2.1 16.1 3.1 18.4 5.2 20.5 L 13 28.2 L 20.8 20.5 C 22.9 18.4 23.8 16.2 23.8 12.8 C 23.6 7.07 20 2.2 13 2.2 Z" fill="${
                color || 'var(--primary-color)'
              }"></path></svg>
            </svg>
          </div>
        </div>
      `,
    });
  }

  _geoJsonToMarker({ geoJson }) {
    return L.geoJSON(geoJson, {
      pointToLayer: (feature, latlng) =>
        L.marker(latlng, {
          ...feature.properties,
          icon: this._createMarkerIcon(feature.properties),
        }).bindPopup(`
          <div id="mapPopupHeader">
            <span>${feature.properties.placaComApelido}</span>
          </div>
          <div id="mapPopupBody">
            <div style="display: ${feature.properties.endereco ? 'block' : 'none'}">
              <b>Endereço:</b>
              <br>
              <span>${feature.properties.endereco}</span>
            </div>
            <div style="display: ${feature.properties.endereco ? 'block' : 'none'}">
              <b>Cidade - Estado:</b>
              <br>
              <span>${feature.properties.cidade} - ${feature.properties.estado}</span>
            </div>
            <div>
              <b>Data Hora:</b>
              <br>
              <span>${feature.properties.ultimaPosicaoFormatado}</span>
            </div>
            <div>
              <b>Velocidade:</b>
              <br>
              <span>
                <i class="material-icons"
                  style="font-size: 11px;
                  color: ${!feature.properties.ignicao ? '#980A1A' : '#4AAE4E'}">
                  lens
                </i>
                ${feature.properties.velocidadeFormatada}
              </span>
            </div>
            <div>
              <b>Condutor:</b>
              <br>
              <span>${feature.properties.motorista || 'Não identificado'}</span>
            </div>
            ${
              isIframe()
                ? ''
                : `
                  <div style="margin-top:8px;text-align:center">
                    <a class="gs-link"
                      target="_blank"
                      href="${`https://maps.google.com/maps?layer=c&q=${latlng.lat},${latlng.lng}&cbll=${latlng.lat},${latlng.lng}&cbp=11,0,0,0,0&z=17&ll=${latlng.lat},${latlng.lng}`}">
                      Ver no StreetView
                    </a>
                  </div>
                `
            }
          </div>
          <div id="mapPopupFooter">
            <span> Lat: ${latlng.lat} </span>
            <span> Lon: ${latlng.lng} </span>
          </div>
				`),
    }).getLayers()[0];
  }

  _geoJsonToLGeoJson({ geoJson, markerIcon }) {
    switch (geoJson.type) {
      case 'Point':
        return this._geoJsonToLGeoJsonPointOfInterest({ geoJson });
      default:
        return this._geoJsonToLGeoJsonControlArea({ geoJson, markerIcon });
    }
  }

  _geoJsonToLGeoJsonControlArea({ geoJson, markerIcon }) {
    return L.geoJSON(geoJson, {
      style: { color: geoJson.properties.color },
      onEachFeature: (feature, layer) => {
        layer.bindPopup(`
          <div id="mapPopupHeader">
            <span>${feature.properties.description}</span>
          </div>
          <div id="mapPopupBody">
            <div>
              <b>Descrição:</b>
              <br>
              <span>${feature.properties.extendedDescription || '---'}</span>
            </div>
            <div>
              <b>Categoria:</b>
              <br>
              <span>${feature.properties.categoryName || '---'}</span>
            </div>
            <div>
              <b>Privacidade:</b>
              <br>
              <span>${feature.properties.privacy || '---'}</span>
            </div>
            <div>
              <b>Status:</b>
              <br>
              <span>${feature.properties.providerStatusDesc || '---'}</span>
            </div>
          </div>
          <div id="mapPopupFooter">
            <span> Lat: ${parseFloat(feature.properties.latitude).toFixed(4)} </span>
            <span> Lon: ${parseFloat(feature.properties.longitude).toFixed(4)} </span>
          </div>
        `);
      },
      pointToLayer: (feature, latlng) => {
        switch (feature.type) {
          case 'Polygon':
            return L.polygon(latlng, feature.properties);
          case 'Polyline':
            return L.polyline(latlng, feature.properties);
          case 'Rectangle':
            return L.rectangle(latlng, feature.properties);
          default:
            return feature.properties.radius
              ? L.circle(latlng, feature.properties)
              : L.marker(latlng, {
                  ...feature.properties,
                  icon: markerIcon || this._createMarkerIcon(feature.properties),
                });
        }
      },
    }).getLayers()[0];
  }

  _geoJsonToLGeoJsonPointOfInterest({ geoJson }) {
    switch (geoJson.properties.categoryType) {
      case 1:
        geoJson.properties.color = '#315A94';
        break;
      case 2:
        geoJson.properties.color = '#8A4B08';
        break;
      case 3:
        geoJson.properties.color = '#EE1131';
        break;
      case 4:
        geoJson.properties.color = '#FFBF00';
        break;
      case 5:
        geoJson.properties.color = '#04B404';
        break;
      default:
        geoJson.properties.color = '#555555';
        break;
    }
    return L.geoJSON(geoJson, {
      onEachFeature: (feature, layer) => {
        layer.bindPopup(`
				  <div id="mapPopupHeader">
            <span>${feature.properties.name}</span>
          </div>
          <div id="mapPopupBody">
            <div>
              <b>Descrição:</b>
              <br>
              <span>${feature.properties.description || '---'}</span>
            </div>
            <div>
              <b>Categoria:</b>
              <br>
              <span>${feature.properties.categoryName || '---'}</span>
            </div>
            <div>
              <b>Privacidade:</b>
              <br>
              <span>${feature.properties.privacy || '---'}</span>
            </div>
          </div>
          <div id="mapPopupFooter">
            <span> Lat: ${parseFloat(feature.properties.latitude).toFixed(6)} </span>
            <span> Lon: ${parseFloat(feature.properties.longitude).toFixed(6)} </span>
          </div>
			`);
      },
      pointToLayer: (feature, latlng) =>
        L.marker(latlng, {
          ...feature.properties,
          icon: this._createPointOfInterestMarkerIcon(
            feature.properties.name,
            feature.properties.color,
          ),
        }).on('click', this._pointOfInterestMarkerOnClick.bind(this)),
    }).getLayers()[0];
  }

  _toggleNearBySearch() {
    const selectMarker = document.querySelector('#marker-point');
    const cardGeoVehicle = document.querySelector('.card-geo-vehicle');

    const clickToLatLng = evt => {
      const coord = evt.latlng;

      if (this._clickToLatLngMarker) {
        this.mapLayer.removeLayer(this._clickToLatLngMarker);
      }

      this._clickToLatLngMarker = L.marker([coord.lat, coord.lng]);
      this._clickToLatLngMarker.addTo(this.mapLayer);
      this._searchNearBy(coord.lat, coord.lng);
    };

    if (!selectMarker.hasAttribute('active')) {
      selectMarker.setAttribute('active', '');
      cardGeoVehicle.setAttribute('active', '');

      this._clickToLatLng = clickToLatLng.bind(this);
      this.mapLayer.on('click', this._clickToLatLng);
    } else {
      selectMarker.removeAttribute('active');
      selectMarker.classList.remove('H_active');
      cardGeoVehicle.removeAttribute('active');

      if (this._clickToLatLngMarker) {
        this.mapLayer.removeLayer(this._clickToLatLngMarker);
        this._clickToLatLngMarker = null;
      }

      this.mapLayer.off('click', this._clickToLatLng);
      this._clickToLatLng = null;
      this.context._nearbyVehicleList = [];
    }
  }

  _searchNearBy(lat, lng) {
    const vehicles = (this.context.reportDataset || []).map(item => item.id);
    this.$http({
      url: `${this.urlApi}/Vehicle/GetVehiclesByCoordinates`,
      method: 'POST',
      data: {
        latitude: lat,
        longitude: lng,
        vehicles: vehicles.reduce((acc, item) => {
          if (acc.includes(item)) {
            return acc;
          }
          return acc.concat(item);
        }, []),
      },
    }).then(
      success => {
        this.context._nearbyVehicleList = success.data.data;
      },
      () => {
        this.context._nearbyVehicleList = [];
      },
    );
  }

  _pointOfInterestMarkerOnClick(e) {
    const cardGeoVehicle = document.querySelector('.card-geo-vehicle');

    if (!cardGeoVehicle.hasAttribute('active')) {
      cardGeoVehicle.setAttribute('active', '');
    }

    this.mapLayer.on('click', this._closeCardGeoVehicle);
    this._searchNearBy(e.latlng.lat, e.latlng.lng);
  }

  _closeCardGeoVehicle() {
    const selectMarker = document.querySelector('#marker-point');
    const cardGeoVehicle = document.querySelector('.card-geo-vehicle');

    if (!selectMarker.hasAttribute('active')) {
      cardGeoVehicle.removeAttribute('active');
    }
  }

  _hasModule(module) {
    return this.__modules.includes(module);
  }
  /* */

  /* Observers */
  /* */
}

export { PowerMapVehiclesLeafletProvider };
