import angular from 'angular';
import moment from 'moment';
import * as Comlink from 'comlink';
import 'angular-sanitize';

import '../power-dropdown/power-dropdown';
import '../power-popup/power-popup';
import '../power-toast/power-toast';
import '../../directives/ng-resize/ng-resize';
import '../../directives/infinite-scroll/infinite-scroll';
import { PowerGridController } from '../power-grid/power-grid';

import template from './power-grid-positions.html';
import './power-grid-positions.scss';

class PowerGridPositionsController extends PowerGridController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$state',
      '$ngRedux',
      '$http',
      '$timeout',
      '$filter',
      'commonServices',
      'urlApi',
      'version',
      'tipoApp',
      'mobile',
    ];
  }

  constructor(
    $element,
    $scope,
    $state,
    $ngRedux,
    $http,
    $timeout,
    $filter,
    commonServices,
    urlApi,
    version,
    tipoApp,
    mobile,
  ) {
    super($element, $scope, $state, $http, $timeout, $filter, commonServices, urlApi, $ngRedux);

    Object.assign(this, { $ngRedux, version, tipoApp, mobile });

    this.__appBehavior = $ngRedux.connect(behavior =>
      Object({
        state: behavior.state,
        session: behavior.session,
      }),
    )(this);

    this.toastText = '';

    this.searchDriver = '';

    this.driverList = [{ id: null, description: 'Não identificado' }, { holder: true }];

    this.driverImagePopup = {
      driverList: [],
      previousSelectedDriver: null,
      selectedDriver: null,
      detail: {},
      shouldReload: false,
    };

    this.onClickEvent = null;

    if (this.worker) this.worker.terminate();

    this.worker = new Worker('./power-grid-positions.worker.js');
    this.workerService = Comlink.wrap(this.worker);
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();

    this._getDriverList().then(result =>
      Object.assign(this.driverList, this.driverList.concat(result.data.data)),
    );
  }
  /* */

  /* Public */
  /* */

  /* Private */
  _parseDate(date) {
    return new Date(date.setHours(date.getHours() - 3));
  }

  _toggleMediaFilePopup(open, row) {
    if (open) {
      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: true },
          bubbles: true,
          composed: true,
        }),
      );

      this.$http({
        url: `${this.urlApi}/TelematicsReport/GetMidiaByEvents`,
        method: 'POST',
        data: { request: { mrvId: row.id, dataHora: new Date(row.DataHora) } },
      })
        .then(result => {
          if (result.hasError) {
            this.$.dispatchEvent(
              new CustomEvent('toggleLoader', {
                detail: { showLoader: false },
                bubbles: true,
                composed: true,
              }),
            );
            Object.assign(this, {
              toastText: result.data.data[0].image.result,
            });
            this.$.querySelector('power-toast#grid-toast').toggle(true);
            return;
          }

          const { data } = result.data;
          const { data: items } = data;

          this.$.querySelector('#media-files-popup-body').scroll({ top: 0 });
          this.$.querySelector('#media-files-popup-body').innerHTML = '';

          items.forEach(item => {
            const imgElement = document.createElement('img');
            imgElement.src = item.image.isUrl
              ? item.image.result
              : `data:image/png;base64,${item.image.result}`;

            this.$.querySelector('#media-files-popup-body').appendChild(imgElement);
          });

          this.$.querySelector('#media-files-popup').toggle();
        })
        .finally(() => {
          this.$scope.$apply();
          this.$.dispatchEvent(
            new CustomEvent('toggleLoader', {
              detail: { showLoader: false },
              bubbles: true,
              composed: true,
            }),
          );
        });
    } else {
      this.$.querySelector('#media-files-popup').toggle();
    }
  }

  _toggleDriverImagePopup(open, row) {
    // @NOTE: se for identificação por acessório, a função é encerrada.
    if (row && (row.tipoIdentificacao == 1 || row.tipoIdentificacao == 2)) {
      return;
    }

    this.searchDriver = '';
    if (!this.onClickEvent)
      this.onClickEvent = this.$.querySelector('.power-dropdown-button').addEventListener(
        'click',
        this._focusSearch.bind(this),
      );
    if (open) {
      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: true },
          bubbles: true,
          composed: true,
        }),
      );
      const rowDate = new Date(row.dataHora);
      const requestDate = this._parseDate(new Date(rowDate));
      this.$http({
        url: `${this.urlApi}/FleetCam/GetDriversFace`,
        method: 'POST',
        data: {
          request: {
            identificationType: row.tipoIdentificacao,
            beginDate: requestDate,
            endDate: requestDate,
            vehicleId: this.datasetParams.id,
          },
        },
      })
        .then(
          result => {
            if (result.data.data[0].image.hasError) {
              this.$.dispatchEvent(
                new CustomEvent('toggleLoader', {
                  detail: { showLoader: false },
                  bubbles: true,
                  composed: true,
                }),
              );
              Object.assign(this, {
                toastText: result.data.data[0].image.result,
              });
              this.$.querySelector('power-toast#grid-toast').toggle(true);
              return;
            }
            Object.assign(this.driverImagePopup, {
              detail: Object.assign(result.data.data[0], {
                latitude: row.latitude,
                longitude: row.longitude,
              }),
              driverList: this._parseDriverList(result.data.data[0]),
              selectedDriver: {
                id: result.data.data[0].mrvTmtId || null,
                ulid: result.data.data[0].tmtUlid || null,
              },
              previousSelectedDriver: result.data.data[0].mrvTmtId || null,
            });
            this.$.querySelector('#driver-image-popup').toggle();
          },
          error => {
            Object.assign(this, { toastText: error.data.data[0] });
            this.$.querySelector('power-toast#grid-toast').toggle(true);
          },
        )
        .finally(() => {
          this.$.dispatchEvent(
            new CustomEvent('toggleLoader', {
              detail: { showLoader: false },
              bubbles: true,
              composed: true,
            }),
          );
          if (this.driverImagePopup.detail.image) {
            const drawImageInterval = setInterval(() => {
              if (document.querySelector('#driver-image-popup-body').clientWidth) {
                this._drawImage(
                  this.driverImagePopup.detail.image.isUrl
                    ? this.driverImagePopup.detail.image.result
                    : `data:image/jpeg;base64,${this.driverImagePopup.detail.image.result}`,
                  document.createElement('canvas'),
                  this.$.querySelector('#driver-image-popup-body'),
                );

                if (this.session.trainingMode) {
                  const canvas = this.$.querySelector('#driver-image-popup-body > canvas');
                  canvas.classList.add('blurred');
                }
                clearInterval(drawImageInterval);
              }
            }, 250);
          }
        });
    } else {
      if (this.driverImagePopup.shouldReload) {
        this.getDataset();
        this.driverImagePopup.shouldReload = false;
      }
      this.$.querySelector('#driver-image-popup').toggle();
    }
  }

  _setDriver(selectedDriver) {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );
    this.$http({
      url: `${this.urlApi}/FleetCam/IdentifyDriver`,
      method: 'POST',
      data: {
        request: {
          tmtId: selectedDriver.id,
          tmsId: this.driverImagePopup.detail.tmsId,
          mrvId: this.driverImagePopup.detail.mrvId,
          mrvVeiId: this.driverImagePopup.detail.mrvVeiId,
          mrvDataHoraExibicao: new Date(this.driverImagePopup.detail.mrvDataHoraExibicao),
          evento: this.driverImagePopup.detail.evento,
          latitude: this.driverImagePopup.detail.latitude,
          longitude: this.driverImagePopup.detail.longitude,
          imageName: this.driverImagePopup.detail.driverFaces,
          bucket: this.driverImagePopup.detail.bucketDriverFaces,
          tmtUlid: selectedDriver.ulid,
        },
      },
    })
      .then(
        () => {
          this.driverImagePopup.shouldReload = true;
          Object.assign(this, { toastText: 'Condutor selecionado' });
          this.$.querySelector('power-toast#grid-toast').toggle(true);
        },
        () => {
          Object.assign(this, {
            toastText: 'Ops, não foi possível selecionar o Condutor',
          });
          this.$.querySelector('power-toast#grid-toast').toggle(true);
        },
      )
      .finally(() =>
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        ),
      );
  }

  _getDriverList() {
    return this.$http({
      url: `${this.urlApi}/Driver/GetNames`,
      method: 'POST',
    });
  }

  _getDriverDescription(driverId) {
    if (!this.driverImagePopup.driverList) return '';
    const selectedDriver = this.driverImagePopup.driverList.filter(ele => ele.id == driverId)[0];
    if (!selectedDriver) return '';
    return selectedDriver.description;
  }

  _parseDriverList(detail) {
    const driverList = Object.clone([], this.driverList);
    if (driverList.find(driver => driver.id == detail.mrvTmtId)) return driverList;
    return driverList.map(driver =>
      !driver.holder
        ? driver
        : Object.assign(driver, {
            id: detail.mrvTmtId,
            description: detail.tmtNome,
          }),
    );
  }

  _drawImage(url, canvas, canvasContainer) {
    this.$.querySelector('#driver-image-popup-loader').toggle(true);
    if (canvasContainer.querySelector('canvas'))
      canvasContainer.replaceChild(canvas, canvasContainer.querySelector('canvas'));
    else canvasContainer.appendChild(canvas);

    Object.assign(canvas, {
      width: canvasContainer.clientWidth,
      height: canvasContainer.clientHeight,
    });

    const image = new Image();
    const ctx = canvas.getContext('2d');
    let lastX = canvas.width / 2;
    let lastY = canvas.height / 2;
    let dragStart;
    let dragged;

    const redraw = function redraw() {
      const p1 = ctx.transformedPoint(0, 0);
      const p2 = ctx.transformedPoint(canvas.width, canvas.height);
      ctx.clearRect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y);

      if (canvas.width > image.width) {
        ctx.drawImage(image, 0, 0);
      } else {
        ctx.drawImage(image, 0, 0, canvas.width, (canvas.width * image.height) / image.width);
      }

      ctx.save();
    };
    const zoom = function zoom(clicks) {
      const pt = ctx.transformedPoint(lastX, lastY);
      ctx.translate(pt.x, pt.y);
      // eslint-disable-next-line no-restricted-properties
      const factor = Math.pow(1.1, clicks);
      ctx.scale(factor, factor);
      ctx.translate(-pt.x, -pt.y);
      redraw();
    };
    const handleScroll = function handleScroll(evt) {
      const delta = evt.wheelDelta ? evt.wheelDelta / 40 : evt.detail ? -evt.detail : 0;
      if (delta) zoom(delta);
      return evt.preventDefault() && false;
    };
    const handleMouseUp = function handleMouseUp(evt) {
      dragStart = null;
      if (!dragged) zoom(evt.shiftKey ? -1 : 1);
    };
    const handleMouseDown = function handleMouseDown(evt) {
      // eslint-disable-next-line no-multi-assign
      document.body.style.userSelect = 'none';
      lastX = evt.offsetX || evt.pageX - canvas.offsetLeft;
      lastY = evt.offsetY || evt.pageY - canvas.offsetTop;
      dragStart = ctx.transformedPoint(lastX, lastY);
      dragged = false;
    };
    const handleMouseMove = function handleMouseMove(evt) {
      lastX = evt.offsetX || evt.pageX - canvas.offsetLeft;
      lastY = evt.offsetY || evt.pageY - canvas.offsetTop;
      dragged = true;
      if (dragStart) {
        const pt = ctx.transformedPoint(lastX, lastY);
        ctx.translate(pt.x - dragStart.x, pt.y - dragStart.y);
        redraw();
      }
    };
    // eslint-disable-next-line no-shadow
    const trackTransforms = function trackTransforms(ctx) {
      const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      const savedTransforms = [];
      let xform = svg.createSVGMatrix();
      ctx.getTransform = function () {
        return xform;
      };

      const { save } = ctx;
      ctx.save = function () {
        savedTransforms.push(xform.translate(0, 0));
        return save.call(ctx);
      };

      const { restore } = ctx;
      ctx.restore = function () {
        xform = savedTransforms.pop();
        return restore.call(ctx);
      };

      const { scale } = ctx;
      ctx.scale = function (sx, sy) {
        xform = xform.scaleNonUniform(sx, sy);
        return scale.call(ctx, sx, sy);
      };

      const { rotate } = ctx;
      ctx.rotate = function (radians) {
        xform = xform.rotate((radians * 180) / Math.PI);
        return rotate.call(ctx, radians);
      };

      const { translate } = ctx;
      ctx.translate = function (dx, dy) {
        xform = xform.translate(dx, dy);
        return translate.call(ctx, dx, dy);
      };

      const { transform } = ctx;
      ctx.transform = function (a, b, c, d, e, f) {
        const m2 = svg.createSVGMatrix();
        Object.assign(m2, { a, b, c, d, e, f });
        xform = xform.multiply(m2);
        return transform.call(ctx, a, b, c, d, e, f);
      };

      const { setTransform } = ctx;
      ctx.setTransform = function (a, b, c, d, e, f) {
        Object.assign(xform, { a, b, c, d, e, f });
        return setTransform.call(ctx, a, b, c, d, e, f);
      };

      const pt = svg.createSVGPoint();
      ctx.transformedPoint = function (x, y) {
        Object.assign(pt, { x, y });
        return pt.matrixTransform(xform.inverse());
      };
    };

    image.onload = () => {
      trackTransforms(ctx);
      redraw();
      setTimeout(() => zoom(0));
      this.$.querySelector('#driver-image-popup-loader').toggle(false);
    };

    image.src = url;

    canvas.addEventListener('mousedown', handleMouseDown, false);
    canvas.addEventListener('mousemove', handleMouseMove, false);
    canvas.addEventListener('mouseup', handleMouseUp, false);

    canvas.addEventListener('DOMMouseScroll', handleScroll, false);
    canvas.addEventListener('mousewheel', handleScroll, false);
  }

  _selectRow(column, row) {
    const result = super._selectRow(column, row);

    if (column.type !== 'DriverIcon') {
      return result;
    }

    return (result || ![0, 1, 2].includes(row.tipoIdentificacao)) && !!row[column.field];
  }

  _focusSearch() {
    setTimeout(() => this.$.querySelector('search-slot input').focus(), 250);
  }

  _goToLink(
    index,
    tableRowData,
    routeName,
    routeLink,
    stateFixedParams,
    linkStateConfig,
    getDataMethod,
    backPagination,
    fixedFilters,
  ) {
    if (!routeName && !routeLink) {
      return;
    }

    super._goToLink(
      index,
      tableRowData,
      routeName,
      routeLink,
      stateFixedParams,
      linkStateConfig,
      getDataMethod,
      backPagination,
      fixedFilters,
    );
  }

  _showToast(message) {
    Object.assign(this, {
      toastText: message,
    });
    this.$.querySelector('power-toast#grid-toast').toggle(true);
  }

  _selectDriver(driverId, driverUlid) {
    this.driverImagePopup.selectedDriver = {
      id: driverId,
      ulid: driverUlid,
    };
  }

  _toDate(date) {
    return moment(date).utcOffset(-3 - moment(date).utcOffset() / 60)._d;
  }

  _getTemperatureColor(row) {
    let colorResponse = '';
    switch (row.indicadorTemperatura) {
      case 0:
        colorResponse = '#5CAAFF';
        break;
      case 1:
        colorResponse = '#555555';
        break;
      case 2:
        colorResponse = '#FF4F63';
        break;
      default:
        colorResponse = '#555555';
        break;
    }
    return colorResponse;
  }

  /* */
}

class PowerGridPositions {
  constructor() {
    this.template = template;
    this.bindings = {
      /* common */
      datasetMethod: '=?',
      isPaginated: '=?',
      page: '=?',
      pageSize: '=?',
      pageRows: '=?',
      lastPage: '=?',
      datasetLength: '=?',
      hasRowSelection: '=?',
      dateGranularity: '=?',
      /* underscore */
      gridHeaders: '=?',
      gridDataset: '=?',
      gridHeadersCategories: '=?',
      sortHeader: '=?',
      sortDirection: '=?',
      selectedRows: '=?',
      /* duble underscore */
      mainHeader: '=?',
      mongoGridId: '=?',
      headerParams: '=?',
      datasetParams: '=?',
    };
    this.controller = PowerGridPositionsController;
  }
}

angular
  .module('power-grid-positions', [
    'ngSanitize',
    'power-dropdown',
    'power-popup',
    'power-toast',
    'ng-resize',
    'infinite-scroll',
  ])
  .component('powerGridPositions', new PowerGridPositions());

export { PowerGridPositionsController };
