import angular from 'angular';
import moment from 'moment';
import 'ng-redux';

import '../power-dropdown/power-dropdown';
import '../power-popup/power-popup';
import '../power-toast/power-toast';
import '../power-crud-combobox/power-crud-combobox';

import template from './power-media-request-utilization.html';
import './power-media-request-utilization.scss';

export const CompatibilityTypeEnum = {
  COMPATIBLE: 1,
  INCOMPATIBLE_VERSION: 2,
  INCOMPATIBLE_EQUIPMENT: 3,
};

class PowerMediaRequestUtilizationController {
  static get $inject() {
    return ['$element', '$scope', '$rootScope', '$ngRedux', '$http', '$state', 'urlApi'];
  }

  constructor($element, $scope, $rootScope, $ngRedux, $http, $state, urlApi) {
    Object.assign(this, { $: $element[0], $scope, $rootScope, $ngRedux, $http, $state, urlApi });

    this.__appBehavior = $ngRedux.connect(behavior => {
      const currentState = behavior.state.routeList[behavior.state.routeList.length - 1];
      return Object({
        /* State Storage */
        currentState: currentState || {},
        stateConfig: currentState ? currentState.stateConfig : {},
        /* Session Storage */
        session: behavior.session,
      });
    })(this);

    this.fethingData = false;
    this.utilizationIcon = 'gs_map';

    this.utilizationData = {
      vehicleId: 0,
      vehiclePlate: '',
      startingDateTime: '',
      startingDateTimeFormatted: '',
      startingAddress: '',
      endingDateTime: '',
      endingDateTimeFormatted: '',
      endingAddress: '',
      dataset: [],
    };

    this.selectedMediaType = 'picture';

    this.cameraOptions = [
      { id: 1, description: 'Interna', selected: false, value: ['driver'], count: 1 },
      { id: 2, description: 'Externa', selected: false, value: ['road'], count: 1 },
      { id: 3, description: 'Todas (2)', selected: true, value: ['driver', 'road'], count: 2 },
    ];

    this.pictures = [];
    this.videos = [];

    this.requestedMediaList = [];
    this.requestedMediaCount = 0;
    this.requestMediaLimit = 0;

    this.mediaSecondsList = [];
    this.mediaStartingSecondsList = [];
    this.mediaEndingSecondsList = [];

    this.queryTablet = window.matchMedia('(max-width: 768px)');
    this.queryMobile = window.matchMedia('(max-width: 425px)');
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      getMediaType: this._getMediaType.bind(this),
      setup: this._setup.bind(this),
      clearDataset: this._clearDataset.bind(this),
    });

    for (let i = 0; i < 60; i++) {
      const secondObject = {
        id: i,
        description: i.toString().padStart(2, '0'),
        value: i.toString().padStart(2, '0'),
        selected: i === 0,
      };

      this.mediaSecondsList.push(secondObject);
      this.mediaStartingSecondsList.push(secondObject);
      this.mediaEndingSecondsList.push(secondObject);
    }

    this.$scope.$watch(
      () => this.hasRequest,
      hasRequest => {
        if (hasRequest) {
          this.$.setAttribute('hasRequest', '');
        } else {
          this.$.removeAttribute('hasRequest');
        }
      },
    );
  }

  _getMediaType() {
    return this.selectedMediaType;
  }

  async _setup({
    vehicleId,
    vehiclePlate,
    startingDateTime,
    startingAddress,
    endingDateTime,
    endingAddress,
    lastPositionDateTime,
    compatibility,
    historicalVehicle,
    dataset,
  }) {
    this.utilizationData = {
      vehicleId,
      vehiclePlate,
      startingDateTime,
      startingDateTimeFormatted:
        this.queryMobile.matches === false
          ? this._toIsoStringDate(startingDateTime)
          : this._toIsoStringNoSeconds(startingDateTime),
      startingAddress,
      endingDateTime,
      endingDateTimeFormatted:
        this.queryMobile.matches === false
          ? this._toIsoStringDate(endingDateTime)
          : this._toIsoStringNoSeconds(endingDateTime),
      endingAddress,
      lastPositionDateTime,
      compatibility,
      historicalVehicle,
      dataset,
    };

    this.pictures = [];
    this.videos = [];

    if (this.selectedMediaType === 'picture') {
      this.$.querySelector('#request-input-datetime').value =
        moment(startingDateTime).format('YYYY-MM-DDTHH');
    } else {
      this.$.querySelector('#request-input-starting-datetime').value =
        moment(startingDateTime).format('YYYY-MM-DDTHH');
      this.$.querySelector('#request-input-ending-datetime').value =
        moment(endingDateTime).format('YYYY-MM-DDTHH');
    }

    await this._requestMedia();
  }

  _clearDataset() {
    this.utilizationData = {};
    this.pictures = [];
    this.videos = [];
  }

  async _requestMedia(reload = false) {
    this.fethingData = true;
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    this.pictures = [];
    this.videos = [];

    const isPictureMedia = this.selectedMediaType === 'picture';
    if (
      reload ||
      (isPictureMedia && this.pictures.length == 0) ||
      (!isPictureMedia && this.videos.length == 0)
    ) {
      await this.$http({
        url: `${this.urlApi}/Utilizations/GetMediasByUtilization`,
        method: 'POST',
        data: {
          request: {
            veiculoId: this.utilizationData.vehicleId,
            dataHoraInicial: moment(this.utilizationData.startingDateTime).format(
              'YYYY-MM-DDTHH:mm:ssZ',
            ),
            dataHoraFinal: moment(this.utilizationData.lastPositionDateTime).format(
              'YYYY-MM-DDTHH:mm:ssZ',
            ),
            tipoMidia: isPictureMedia ? 2 : 1,
            midiaPorEvento: true,
            midiaPorPedido: true,
          },
        },
      })
        .then(
          result => {
            if (!result.data.hasError && result.data.data) {
              const response = result.data.data;
              const mediaData = response.data;
              this.requestedMediaList = response.requisicoesRealizadasMes;
              this.requestMediaLimit = response.qtdLimiteRequisicoesMes;

              mediaData.forEach(media => {
                const mediaItem = {
                  title: media.eventoGerador,
                  processed: !!media.arquivo,
                  dateTime: this._toDate(media.dataHoraExibicao),
                  bucketName: media.bucketName,
                  fileName: media.arquivo,
                  image: media.image,
                  rawDateTime: media.dataHoraExibicao,
                  unavailable: media.midiaExpirada,
                };

                if (media.tipoMidia == 'VIDEO' && !isPictureMedia) {
                  this.videos.push(mediaItem);
                }
                if (media.tipoMidia != 'VIDEO' && isPictureMedia) {
                  this.pictures.push(mediaItem);
                }
              });
            } else {
              Object.assign(this, {
                toastText: 'Ops, não foi possível carregar as mídias.',
              });
              this.$.querySelector('power-toast#media-request-utilization-toast').toggle(true);
            }
          },
          () => {
            Object.assign(this, {
              toastText: 'Ops, não foi possível carregar as mídias',
            });
            this.$.querySelector('power-toast#media-request-utilization-toast').toggle(true);
          },
        )
        .finally(() => {
          this.fethingData = false;
          this._requestUpdate();

          this.$.dispatchEvent(
            new CustomEvent('toggleLoader', {
              detail: { showLoader: false },
              bubbles: true,
              composed: true,
            }),
          );
        });
    } else {
      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: false },
          bubbles: true,
          composed: true,
        }),
      );
    }
  }

  _requestUpdate() {
    if (!this.$scope.$$phase && !this.$rootScope.$$phase) {
      this.$scope.$apply();
    }
  }

  _midiaSelected(midia, midiaType) {
    if (midia.unavailable) return;

    this.$.dispatchEvent(
      new CustomEvent('mediaSelected', {
        detail: { data: midia, midiaType },
        bubbles: true,
        composed: true,
      }),
    );
  }

  _changeMediaType(mediaType) {
    this.selectedMediaType = mediaType;
    this._requestMedia(true);
    this._requestUpdate();
  }

  _toggleNewMediaRequestPopup(open) {
    this.$.querySelector('#popup-request-new-media').toggle(open);
  }

  _saveNewMediaRequest() {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    const isPictureMedia = this.selectedMediaType === 'picture';
    const requestCount = this.cameraOptions.find(p => p.selected).count;
    const isMobile = this.queryMobile.matches === true;
    let requestStartingDateTimeValue =
      this.$.querySelector('#request-input-datetime')?.value ??
      this.$.querySelector('#request-input-starting-datetime')?.value;
    let requestEndingDateTimeValue = this.$.querySelector('#request-input-ending-datetime')?.value;

    if (isMobile) {
      if (isPictureMedia && requestStartingDateTimeValue && this.mediaSecondsValue) {
        requestStartingDateTimeValue = `${requestStartingDateTimeValue}:${this.mediaSecondsValue}`;
      } else if (
        requestStartingDateTimeValue &&
        requestEndingDateTimeValue &&
        this.mediaStartingSecondsValue &&
        this.mediaEndingSecondsValue
      ) {
        requestStartingDateTimeValue = `${requestStartingDateTimeValue}:${this.mediaStartingSecondsValue}`;
        requestEndingDateTimeValue = `${requestEndingDateTimeValue}:${this.mediaEndingSecondsValue}`;
      }
    }

    const requestStartingDateTime = requestStartingDateTimeValue
      ? moment(`${requestStartingDateTimeValue}-03:00`)
      : null;
    const requestEndingDateTime = requestEndingDateTimeValue
      ? moment(`${requestEndingDateTimeValue}-03:00`)
      : null;
    const utilizationStartingDateTime = moment(this.utilizationData.startingDateTime);
    const utilizationEndingDateTime = moment(this.utilizationData.endingDateTime);

    let toastText = '';

    // validation --
    if (isPictureMedia) {
      if (!requestStartingDateTime) {
        toastText = 'O campo de Data e Hora precisa ser preenchido corretamente.';
      } else if (
        requestStartingDateTime < utilizationStartingDateTime ||
        requestStartingDateTime > utilizationEndingDateTime
      ) {
        toastText = 'Selecione uma data e hora dentro do período.';
      } else if (this.queryMobile.matches === true && !this.mediaSecondsValue) {
        toastText = 'Obrigatório o preenchimento do campo segundos.';
      }
    } else if (!requestStartingDateTime || !requestEndingDateTime) {
      toastText = 'Os campos de Data e Hora precisam ser preenchidos corretamente.';
    } else if (
      this.queryMobile.matches === true &&
      (!this.mediaStartingSecondsValue || !this.mediaEndingSecondsValue)
    ) {
      toastText = 'Obrigatório o preenchimento dos campos segundos.';
    } else if (requestEndingDateTime <= requestStartingDateTime) {
      toastText = 'A data hora final deve ser maior que a inicial.';
    } else if (
      requestStartingDateTime < utilizationStartingDateTime ||
      requestEndingDateTime > utilizationEndingDateTime
    ) {
      toastText = 'A data hora inicial e final devem estar dentro do período.';
    } else if (
      Math.abs(moment(requestStartingDateTime).diff(moment(requestEndingDateTime), 'second')) > 180
    ) {
      toastText = 'Não é possivel requisitar mais de 3 minutos de gravação.';
    }

    if (requestCount > this.requestMediaLimit - this.requestedMediaList.length) {
      toastText = 'Não é possivel requisitar a gravação de mais de uma câmera.';
    }

    if (toastText) {
      Object.assign(this, { toastText });
      this.$.querySelector('power-toast#media-request-utilization-toast').toggle(true);
      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: false },
          bubbles: true,
          composed: true,
        }),
      );

      return;
    }

    this.$http({
      url: `${this.urlApi}/Utilizations/SendMediasOrderByUtilization`,
      method: 'POST',
      data: {
        request: {
          veiculoId: this.utilizationData.vehicleId,
          dataHoraViagem: moment(this.utilizationData.startingDateTime).format(
            'YYYY-MM-DDTHH:mm:ssZ',
          ),
          dataHoraInicial: moment(requestStartingDateTime).format('YYYY-MM-DDTHH:mm:ssZ'),
          dataHoraFinal: isPictureMedia
            ? null
            : moment(requestEndingDateTime).format('YYYY-MM-DDTHH:mm:ssZ'),
          tipoMidia: isPictureMedia ? 2 : 1,
          cameras: this.cameraOptions.find(p => p.selected).value,
        },
      },
    })
      .then(
        result => {
          if (!result.hasError) {
            toastText = 'Requisição de mídias realizada com sucesso.';
            this._toggleNewMediaRequestPopup();
            this.$.dispatchEvent(
              new CustomEvent('mediaRequested', {
                detail: {
                  value: requestCount,
                  midiaType: isPictureMedia ? 'FOTO' : 'VIDEO',
                },
                bubbles: true,
                composed: true,
              }),
            );
          } else {
            toastText = 'Ops, não foi possível requisitar as mídias';
          }
          Object.assign(this, { toastText });
        },
        error => {
          Object.assign(this, {
            toastText: error.data.data[0],
          });
        },
      )
      .finally(() => {
        this.$.querySelector('power-toast#media-request-utilization-toast').toggle(true);
        this._requestMedia(true);
      });
  }

  _selectCameraOption(selectedOption) {
    this.cameraOptions.forEach(option => {
      option.selected = option.id == selectedOption.id;
    });
  }

  _getSelectedCameraOption() {
    return this.cameraOptions.find(option => option.selected).description;
  }

  _selectQualityOption(selectedOption) {
    this.qualityOptions.forEach(option => {
      option.selected = option.id == selectedOption.id;
    });
  }

  _getSelectedQualityOption() {
    return this.qualityOptions.find(option => option.selected).description;
  }

  _toDate(date) {
    return moment(date)
      .utcOffset(-3 - moment(date).utcOffset() / 60)
      ._d.toLocaleString()
      .replace(',', '');
  }

  /** ***********  ✨ Codeium Command ⭐  ************ */
  /**
   * Returns a date as an ISO string, but without the millisecond part.
   * @param {Date} date - The date to be converted.
   * @returns {string} The ISO string representation of the date.
   */
  /** ****  980c1f31-9d6a-4d5a-8f65-5b9247fdb6c3  ****** */
  _toIsoStringDate(date) {
    if (!date) return null;

    return moment(date).utcOffset(-3)._d.toISOString().split('.')[0];
  }

  _toIsoStringNoSeconds(date) {
    if (!date) return null;

    return moment(date).seconds(0).milliseconds(0).utcOffset(-3)._d.toISOString().split('.')[0];
  }

  _isRequestDisabled() {
    if (
      this.fethingData ||
      this.utilizationData.compatibility !== CompatibilityTypeEnum.COMPATIBLE ||
      this.utilizationData.historicalVehicle ||
      !this.utilizationData.endingDateTime
    ) {
      return true;
    }

    return this.requestMediaLimit > 0 && this.requestedMediaList.length >= this.requestMediaLimit;
  }

  _isEmptyList() {
    return (
      (this.selectedMediaType === 'picture' && this.pictures.length === 0) ||
      (this.selectedMediaType === 'video' && this.videos.length === 0)
    );
  }

  _getRequestCountDescription() {
    if (this.fethingData) return '--/--';

    return this.queryTablet && this.queryMobile
      ? `${this.requestedMediaList.length}/${this.requestMediaLimit}`
      : `Requisições: ${this.requestedMediaList.length}/${this.requestMediaLimit}`;
  }

  _getTippyTitle() {
    const { vehicleId, compatibility, historicalVehicle, endingDateTime } = this.utilizationData;
    const utililizationDescription = this.utilizationDescription || 'utilização';

    if (!vehicleId) return 'Nenhum veículo selecionado';
    if (compatibility === CompatibilityTypeEnum.INCOMPATIBLE_VERSION)
      return 'Equipamento com versão incompatível';
    if (compatibility === CompatibilityTypeEnum.INCOMPATIBLE_EQUIPMENT)
      return 'Equipamento incompatível com vídeotelemetria';
    if (historicalVehicle) return 'Veículo em dados históricos';
    if (!endingDateTime)
      return `Não é possível requisitar mídias de uma ${utililizationDescription} em andamento`;

    return 'Limite mensal de requisições para o veículo';
  }

  _showTippyToast(message) {
    Object.assign(this, {
      toastText: message || this._getTippyTitle(),
    });
    this.$.querySelector('power-toast#media-request-utilization-toast').toggle(true);
  }

  _toggleRequestedMedias(open) {
    if (open && this.requestedMediaList.length == 0) {
      return;
    }
    this.$.querySelector('#popup-requested-media-list').toggle(open);
  }

  _onClickInfoButton() {
    const { vehicleId, compatibility, historicalVehicle, endingDateTime } = this.utilizationData;
    const hasRequests = this.requestedMediaList.length > 0;
    const isCompatible = compatibility === CompatibilityTypeEnum.COMPATIBLE;

    if (!vehicleId || !isCompatible || historicalVehicle || !endingDateTime || !hasRequests) {
      this._showTippyToast();
    } else {
      this._toggleRequestedMedias(true);
    }
  }

  _clearForm() {
    if (this.selectedMediaType === 'picture') {
      this.$.querySelector('#request-input-datetime').value = null;
    } else {
      this.$.querySelector('#request-input-starting-datetime').value = null;
      this.$.querySelector('#request-input-ending-datetime').value = null;
    }
    this._clearSecondInputs();
  }

  _clearSecondInputs() {
    this.mediaSecondsValue = null;
    this.mediaStartingSecondsValue = null;
    this.mediaEndingSecondsValue = null;

    if (this.selectedMediaType === 'picture') {
      for (const item of this.mediaSecondsList) {
        item.selected = false;
      }
    } else {
      for (const item of this.mediaStartingSecondsList) {
        item.selected = false;
      }
      for (const item of this.mediaEndingSecondsList) {
        item.selected = false;
      }
    }
  }
}

class PowerMediaRequestUtilization {
  constructor() {
    this.template = template;
    this.bindings = {
      reportDataset: '=',
      utilizationIcon: '=',
      utilizationDescription: '=',
      hasRequest: '=',
    };
    this.controller = PowerMediaRequestUtilizationController;
  }
}

angular
  .module('power-media-request-utilization', [
    'ngRedux',
    'power-dropdown',
    'power-popup',
    'power-toast',
  ])
  .component('powerMediaRequestUtilization', new PowerMediaRequestUtilization());
