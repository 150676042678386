import angular from 'angular';

import { PowerFilterController, PowerFilter } from '../power-filter/power-filter';

import template from './power-filter-range.html';
import './power-filter-range.scss';

class PowerFilterRangeController extends PowerFilterController {
  static get $inject() {
    return ['$element', '$scope', '$http', 'urlApi'];
  }

  constructor($element, $scope, $http, urlApi) {
    super($element, $scope, $http, urlApi);

    this._minValue = null;
    this._maxValue = null;
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      toggle: this.toggle.bind(this),
      setFilterValue: this.setFilterValue.bind(this),
    });

    this.sufix = this.sufix || '';
    this.prefix = this.prefix || '';
    this.inputType = this.inputType || 'text';
    this.description = this.description || 'Carregando...';

    this._initializeFilter();
  }
  /* */

  /* Public */
  toggle() {
    document.querySelector('power-filter-menu').toggleFilter(this.filterId);
  }
  /* */

  /* Private */
  _initializeFilter() {
    if (this.condition) [this._minValue, this._maxValue] = this.condition.value;
    else if (this.default) [this._minValue, this._maxValue] = this.default;
    this.setFilterValue([this._minValue, this._maxValue]);
    this._formatDescription([this._minValue, this._maxValue]);
  }

  _formatDescription(values) {
    if (!Array.isArray(values)) return;
    if (
      (!values[0] && !values[1] && !this.allowNegativeValue) ||
      (values[0] == null && values[1] == null && this.allowNegativeValue)
    )
      this.description = this._formatDescriptionWords(`Qualquer ${this.singular}`);
    else if (
      (!values[0] && !this.allowNegativeValue) ||
      (this.allowNegativeValue && values[0] == null)
    )
      this.description = this._formatDescriptionWords(
        `${this.singular} abaixo de ${this.prefix} ${values[1]} ${this.sufix}`,
      );
    else if (
      (!values[1] && !this.allowNegativeValue) ||
      (this.allowNegativeValue && values[1] == null)
    )
      this.description = this._formatDescriptionWords(
        `${this.singular} acima de ${this.prefix} ${values[0]} ${this.sufix}`,
      );
    else
      this.description = this._formatDescriptionWords(
        `${this.singular} entre ${this.prefix} ${values[0]}` +
          ` ${this.sufix} e ${this.prefix} ${values[1]} ${this.sufix}`,
      );
  }

  _onFocus(query) {
    this.$.querySelector(query).setAttribute('focus', '');
  }

  _onBlur(query) {
    this.$.querySelector(query).removeAttribute('focus');
  }

  _clearMinValue() {
    this._minValue = null;

    this.$.querySelector('#filter-min-value input').blur();
    this.$.querySelector('#filter-min-value input').value = '';

    this.setFilterValue([this._minValue, this._maxValue]);
    this._formatDescription([this._minValue, this._maxValue]);
  }

  _clearMaxValue() {
    this._maxValue = null;

    this.$.querySelector('#filter-max-value input').blur();
    this.$.querySelector('#filter-max-value input').value = '';

    this.setFilterValue([this._minValue, this._maxValue]);
    this._formatDescription([this._minValue, this._maxValue]);
  }
  /* */

  /* Observers */
  __evtKeyDownInputValue(evt) {
    if (this.inputType === 'number') {
      const regex = this.allowNegativeValue ? /^[.e]$/g : /^[.e-]$/g;
      if (regex.test(evt.key)) {
        evt.preventDefault();
      }
    }
  }

  __minValueChanged() {
    if (this.inputType === 'number') {
      if (this._minValue && typeof this._minValue !== 'number') {
        this._clearMinValue();
      } else if (!this.allowNegativeValue && this._minValue < 0) {
        this._minValue = 0;
      }
    }
    this.setFilterValue([this._minValue, this._maxValue]);
    this._formatDescription([this._minValue, this._maxValue]);
  }

  __maxValueChanged() {
    if (this.inputType === 'number') {
      if (this._maxValue && typeof this._maxValue !== 'number') {
        this._clearMaxValue();
      } else if (!this.allowNegativeValue && this._maxValue < 0) {
        this._maxValue = 0;
      }
    }
    this.setFilterValue([this._minValue, this._maxValue]);
    this._formatDescription([this._minValue, this._maxValue]);
  }
  /* */
}

class PowerFilterRange extends PowerFilter {
  constructor() {
    super();
    Object.assign(this.bindings, {
      allowNegativeValue: '=?',
    });
    this.controller = PowerFilterRangeController;
    this.template = template;
  }
}

angular.module('power-filter-range', []).component('powerFilterRange', new PowerFilterRange());
