import angular from 'angular';
import 'ng-redux';

import '../power-filter-switch/power-filter-switch';
import '../power-filter-input/power-filter-input';
import '../power-filter-range/power-filter-range';
import '../power-filter-range-advanced/power-filter-range-advanced';
import '../power-filter-select/power-filter-select';
import '../power-filter-multi-select/power-filter-multi-select';
import '../power-filter-compound/power-filter-compound';
import '../power-filter-entity/power-filter-entity';
import '../power-filter-period/power-filter-period';
import '../power-filter-calendar/power-filter-calendar';
import '../power-filter-calendar-utc/power-filter-calendar-utc';
import '../power-filter-compound-custom/power-filter-compound-custom';
import { FilterCondition, PowerFilterDataKey } from '../power-filter/power-filter';
import '../../helpers/object-clone/object-clone';

import template from './power-filter-menu.html';
import './power-filter-menu.scss';

class PowerFilterMenuController {
  static get $inject() {
    return ['$element', '$scope', '$ngRedux'];
  }

  constructor($element, $scope, $ngRedux) {
    Object.assign(this, { $: $element[0], $scope, $ngRedux });

    this.__appBehavior = $ngRedux.connect(behavior =>
      Object({
        currentState: behavior.state.routeList[behavior.state.routeList.length - 1],
        isTrip: behavior.session.isTrip,
        __modules: behavior.session.modules,
      }),
    )(this);

    this.watchFiltersInterval = null;
    this.watchFiltersValidateInterval = null;
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      toggle: this.toggle.bind(this),
      toggleFilter: this.toggleFilter.bind(this),
      applyFilters: this.applyFilters.bind(this),
      validateFilters: this.validateFilters.bind(this),
    });

    this.$scope.$watch(
      () => JSON.stringify(this.currentState?.stateConfig?.filterConfig),
      this.__onFilterConfigStateChanged.bind(this),
    );
  }

  $onDestroy() {
    this.__appBehavior();
  }
  /* */

  /* Public */
  toggle() {
    if (!this.$.hasAttribute('open')) this.$.setAttribute('open', '');
    else {
      this.$.removeAttribute('open');
      if (this.currentState.stateConfig.filterConfig)
        this.currentState.stateConfig.filterConfig
          .filter(filter => this._hasModule(filter.validateModule))
          .map(filter => this.$.querySelector(`[data-id="${filter.id}"]`)?.removeAttribute('open'));
    }
  }

  toggleFilter(filterId) {
    const filterNode = this.$.querySelector(`[data-id="${filterId}"]`);
    if (filterNode.hasAttribute('open')) filterNode.removeAttribute('open');
    else filterNode.setAttribute('open', '');
    this.$.querySelectorAll(`[open]:not([data-id="${filterId}"])`).forEach(node =>
      node.removeAttribute('open'),
    );
  }

  applyFilters() {
    const requestHandlerNode = this.$.parentElement.querySelector('#app-content > *');
    const dateControl = this.$.parentElement.querySelector('power-date-control');
    if (!requestHandlerNode.requestDataset) return;
    if (this.watchFiltersInterval) clearInterval(this.watchFiltersInterval);
    this.watchFiltersInterval = setInterval(() => {
      if (
        this.currentState &&
        this.currentState.stateConfig &&
        this.currentState.stateConfig.filterConfig
      ) {
        const filterList = this.currentState.stateConfig.filterConfig
          .filter(filter => this._hasModule(filter.validateModule))
          .map(filter => ({
            ...filter,
          }));
        const readyFilterList = filterList.filter(filter => {
          const filterElement = this.$.querySelector(`[data-id="${filter.id}"]`);
          return !!filterElement && filterElement.loading !== undefined
            ? filterElement.loading === false && !!filter.condition
            : !!filter.condition;
        });
        if (readyFilterList.length == filterList.length) {
          this.currentState.stateConfig.filterConditions = Object.clone(
            [],
            readyFilterList.reduce((acc, filter) => {
              if (filter.type === 'datepicker') {
                acc.concat(this._computeDateTimeFilter(filter));
              } else if (filter.type === 'period') {
                if (filter.invalid) {
                  return acc;
                }

                if (Array.isArray(filter.condition.value) && Array.isArray(filter.default)) {
                  const isDefaultValue = filter.condition.value.every((value, index) => {
                    const defaultValue = filter.default[index];
                    return value === Number(defaultValue || 0);
                  });

                  if (isDefaultValue) {
                    return acc;
                  }
                }
              } else if (filter.advanced && Array.isArray(filter.condition)) {
                filter.condition.forEach(condition => acc.push(condition));
                return acc;
              }

              return acc.concat(filter.condition);
            }, []),
          );
          if (this.$.hasAttribute('open')) this.toggle();
          this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' });
          if (dateControl) {
            const [dateCondition] = filterList.filter(filter => filter.type === 'calendar');
            dateControl.updateDateValue(dateCondition);
          } else {
            requestHandlerNode.requestDataset();
          }
          clearInterval(this.watchFiltersInterval);
        }
      }
    }, 250);
  }

  validateFilters() {
    if (this.currentState.userReportConfig) {
      const requestHandlerNode = this.$.parentElement.querySelector('#app-content > *');
      if (!requestHandlerNode.requestDataset) return;
      if (this.watchFiltersValidateInterval) clearInterval(this.watchFiltersValidateInterval);
      this.watchFiltersValidateInterval = setInterval(() => {
        const filterList = this.currentState.stateConfig.filterConfig
          .filter(filter => this._hasModule(filter.validateModule))
          .map(filter => ({
            ...filter,
          }));
        const readyFilterList = filterList.filter(filter => {
          const filterElement = this.$.querySelector(`[data-id="${filter.id}"]`);
          return !!filterElement && filterElement.loading !== undefined
            ? filterElement.loading === false && !!filter.condition
            : !!filter.condition;
        });
        if (readyFilterList.length === filterList.length) {
          readyFilterList.forEach(filter => {
            this._filterConfigValidate(this._getFilterDataKey(filter), filter);
          });
          clearInterval(this.watchFiltersValidateInterval);
        }
      }, 250);
    }
  }
  /* */

  /* Private */
  _computeDateTimeFilter(filter) {
    const initDate = new Date(filter.condition.value[0]);
    const finDate = new Date(filter.condition.value[1]);
    return new FilterCondition(filter.condition.id, filter.condition.field, [
      new Date(initDate.setHours(initDate.getHours() - initDate.getTimezoneOffset() / 60)),
      new Date(finDate.setHours(finDate.getHours() - finDate.getTimezoneOffset() / 60)),
    ]);
  }

  _filterConfigValidate(filterKey, filterConfigItem) {
    if (this.currentState.userReportConfig) {
      const { filterConfig: userFilterConfig } = this.currentState.userReportConfig;

      const invalidConditionsCount = (field, condition) => {
        let invalidConditions = 0;

        if (condition.preset) {
          const stringfy = value => JSON.stringify(value);
          const { options } = filterConfigItem;
          const option = options.find(opt => stringfy(opt.value) === stringfy(condition.preset));

          if (!option) invalidConditions++;
        } else {
          const _conditionValue = condition.values || condition.value;

          if (
            !Array.isArray(_conditionValue) ||
            _conditionValue.length === 0 ||
            ['input', 'range', 'period', 'calendar', 'calendarUtc'].indexOf(
              filterConfigItem.type,
            ) >= 0
          ) {
            return invalidConditions;
          }

          const checkFilterOptions = options => {
            for (let index = 0; index < _conditionValue.length; index++) {
              if (!options.find(option => _conditionValue[index] === option.value)) {
                invalidConditions++;
              }
            }
          };

          if (filterConfigItem.options) {
            checkFilterOptions(filterConfigItem.options);
          } else if (
            filterConfigItem.filters &&
            typeof filterConfigItem.filters.length === 'number'
          ) {
            checkFilterOptions(filterConfigItem.filters.find(f => f.field == field).options);
          } else if (filterConfigItem.filters && filterConfigItem.activeView) {
            const { activeView, activeEntity } = filterConfigItem;
            const filterkey = this._getFilterDataKey(filterConfigItem);

            const options = document
              .querySelector(`power-filter-menu [data-key=${filterkey}]`)
              .getOptions();

            checkFilterOptions(options[activeView] || options[activeEntity] || options);
          }
        }

        return invalidConditions;
      };

      const userFilterConfigItem = userFilterConfig.find(
        f => this._getFilterDataKey(f) === filterKey,
      );

      if (userFilterConfigItem) {
        const field = userFilterConfigItem.field || userFilterConfigItem.condition.field;

        if (field) {
          const invalidConditions = invalidConditionsCount(field, userFilterConfigItem.condition);

          if (invalidConditions > 0) {
            const warningTitle =
              invalidConditions === 1
                ? `1 item foi omitido do filtro`
                : `${invalidConditions} itens foram omitidos do filtro`;

            const warningButtonClickEvent = () => {
              document.querySelector('#power-popup-filter-warning').toggle({ title: warningTitle });
            };

            this._filterAddWarningButton({ filterKey, warningTitle, warningButtonClickEvent });
          }
        }
      }
    }
  }

  _filterAddWarningButton({ filterKey, warningTitle, warningButtonClickEvent }) {
    const filterHeaderElement = document.querySelector(
      `power-filter-menu [data-key=${filterKey}] .filter-header`,
    );

    if (!document.querySelector(`[data-key=warning-${filterKey}]`)) {
      /* Warning Filter Button */
      const warningFilterButton = document.createElement('div');
      warningFilterButton.setAttribute('data-key', `warning-${filterKey}`);
      warningFilterButton.setAttribute('role', 'button');
      warningFilterButton.setAttribute('class', 'warning-button');

      if (warningButtonClickEvent) {
        warningFilterButton.addEventListener('click', warningButtonClickEvent);
      } else {
        warningFilterButton.style.pointerEvents = 'none';
      }

      const warningFilterButtonIcon = document.createElement('i');
      warningFilterButtonIcon.setAttribute('class', 'material-icons ng-binding');
      warningFilterButtonIcon.innerHTML = 'warning';

      const warningFilterButtonText = document.createElement('span');
      warningFilterButtonText.innerHTML = warningTitle;

      warningFilterButton.append(warningFilterButtonIcon);
      warningFilterButton.append(warningFilterButtonText);

      if (filterHeaderElement) {
        filterHeaderElement.style.backgroundImage = 'url(assets/images/menu-warning.svg)';
        filterHeaderElement.style.backgroundRepeat = 'no-repeat';
        filterHeaderElement.style.backgroundPositionX = '6px';
        filterHeaderElement.style.backgroundPositionY = '10px';
        filterHeaderElement.style.backgroundSize = '16px';

        filterHeaderElement.setAttribute('warning', '');
        filterHeaderElement.parentElement.append(warningFilterButton);
      }

      /* Filter Preview */
      const warningFilterPreview = document.createElement('img');
      warningFilterPreview.setAttribute('src', 'assets/images/menu-warning.svg');
      warningFilterPreview.setAttribute('class', 'warning');
      warningFilterPreview.innerHTML = 'warning';

      const filterPreview = document.querySelector(
        `[data-key=${filterKey}].filter-preview-content-item`,
      );
      if (filterPreview) {
        filterPreview.append(warningFilterPreview);
        filterPreview.setAttribute('warning', '');
      }
    }
  }

  _filterRemoveWarningButton({ filterKey }) {
    const warningFilterButton = document.querySelector(`[data-key=warning-${filterKey}]`);
    const filterPreviewWarning = document.querySelector(
      `[data-key=${filterKey}].filter-preview-content-item img`,
    );

    if (warningFilterButton) {
      const parentelement = warningFilterButton.parentElement;
      parentelement.removeChild(warningFilterButton);

      const filterHeaderElement = parentelement.querySelector('.filter-header');
      filterHeaderElement.style.backgroundImage = null;
      filterHeaderElement.style.backgroundRepeat = null;
      filterHeaderElement.style.backgroundPositionX = null;
      filterHeaderElement.style.backgroundPositionY = null;
      filterHeaderElement.style.backgroundSize = null;
      filterHeaderElement.removeAttribute('warning');
    }

    if (filterPreviewWarning) {
      const parentelement = filterPreviewWarning.parentElement;
      parentelement.removeChild(filterPreviewWarning);
      parentelement.removeAttribute('warning', '');
    }
  }

  _validateConditionalFilters() {
    if (this.currentState.stateConfig.filterConfig) {
      this.currentState.stateConfig.filterConfig
        .filter(filter => this._hasModule(filter.validateModule))
        .forEach(filter => {
          if (filter.conditional) {
            const filterCalendar = this.$.querySelector('power-filter-calendar');
            const selectedDaysCount = filterCalendar.getNumberOfDays();

            if (selectedDaysCount > 7) {
              filter.invalid = true;
              this._filterAddWarningButton({
                warningTitle: 'Para utilizar este filtro, selecione no máximo 7 dias',
                filterKey: this._getFilterDataKey(filter),
              });
            } else {
              filter.invalid = false;
              this._filterRemoveWarningButton({ filterKey: this._getFilterDataKey(filter) });
            }
          }
        });
    }
  }

  _getFilterDataKey(filter) {
    return PowerFilterDataKey(filter);
  }

  _hasModule(module) {
    return !module || this.__modules.includes(module);
  }

  /* */

  /* Observers */
  __onFilterConfigStateChanged() {
    const filterTitleElement = this.$.querySelector('#filter-menu-header > span');
    const filterToggleElement = document.querySelector('#navigation-filter-toggle');
    const filterCounterElement = filterToggleElement?.querySelector('span.filter-counter');

    if (filterToggleElement && filterCounterElement) {
      let countSelectedFilters = 0;
      const filterDataIds = [];

      this.currentState?.stateConfig?.filterConfig?.forEach(filter => {
        if (filter.visible === false || filter.show === false) return;

        if (Array.isArray(filter.condition)) {
          countSelectedFilters += filter.condition.reduce((acc, condition) => {
            if (condition.value?.some(value => value === 0 || !!value)) {
              filterDataIds.push(filter.id);
              return ++acc;
            }
            return acc;
          }, 0);
        } else if (
          filter.condition?.value?.some(value => value === 0 || !!value) ||
          filter.condition?.options?.some(opt => opt.selected)
        ) {
          filterDataIds.push(filter.id);
          countSelectedFilters++;
        }
      });

      this.$.querySelectorAll('#filter-menu-content [active]').forEach(el => {
        el.removeAttribute('active');
      });

      if (countSelectedFilters > 0) {
        filterToggleElement.setAttribute('show-counter', '');
        filterCounterElement.innerHTML = `${countSelectedFilters}`.padStart(2, '0');

        filterTitleElement.innerHTML =
          countSelectedFilters > 1 ? `${countSelectedFilters} Filtros ativos` : '1 Filtro ativo';

        filterDataIds.forEach(id => {
          const _setElementActive = () => {
            const element = this.$.querySelector(`#filter-menu-content [data-id="${id}"]`);
            if (element) {
              element.setAttribute('active', '');
              return true;
            }
            return false;
          };

          if (!_setElementActive()) {
            const interval = setInterval(() => {
              if (_setElementActive()) {
                clearInterval(interval);
              }
            }, 100);
          }
        });
      } else {
        filterToggleElement.removeAttribute('show-counter');
        filterTitleElement.innerHTML = 'Filtros';
      }
    }
  }
  /* */
}

class PowerFilterMenu {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = PowerFilterMenuController;
  }
}

angular
  .module('power-filter-menu', [
    'ngRedux',
    'power-filter-switch',
    'power-filter-input',
    'power-filter-range',
    'power-filter-range-advanced',
    'power-filter-select',
    'power-filter-multi-select',
    'power-filter-compound',
    'power-filter-entity',
    'power-filter-period',
    'power-filter-calendar',
    'power-filter-calendar-utc',
    'power-filter-compound-custom',
  ])
  .component('powerFilterMenu', new PowerFilterMenu());
