import angular from 'angular';
import moment from 'moment/src/moment';
import 'ng-redux';

import noUiSlider from 'nouislider';
import 'nouislider/distribute/nouislider.css';

import '../../directives/ng-resize/ng-resize';

import '../power-fab/power-fab';
import '../power-popup/power-popup';
import '../power-footer/power-footer';
import '../power-toolbar/power-toolbar';
import '../power-dropdown/power-dropdown';
import '../power-pagination/power-pagination';
import '../power-map-utilization/power-map-utilization';
import '../power-navigation-date/power-navigation-date';
import '../power-grid-utilization/power-grid-utilization';
import '../power-pagination-register/power-pagination-register';
import '../power-card-utilization-list/power-card-utilization-list';
import '../power-popup-data-limit-reached/power-popup-data-limit-reached';
import '../power-popup-driver-ai-tiebreaker/power-popup-driver-ai-tiebreaker';
import '../power-popup-delete/power-popup-delete';
import '../power-media-request-utilization/power-media-request-utilization';
import { PowerReportController } from '../power-report/power-report';

import template from './power-report-utilization.html';
import './power-report-utilization.scss';

class PowerReportUtilizationController extends PowerReportController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$rootScope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
    ];
  }

  constructor(
    $element,
    $scope,
    $rootScope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
    );

    Object.assign(this, { $rootScope });

    this._appBehavior = $ngRedux.connect(behavior =>
      Object({
        /* Session Storage */
        isSingleSignon: behavior.session.isSingleSignon,
        isTrainingMode: behavior.session.trainingMode,
        modules: behavior.session.modules,
        themeColors: behavior.session.themeColors,
        isVideoTelemetry: behavior.session.isVideoTelemetry,
      }),
    )(this);

    this.photoBlur = '';
    this.legendList = [];
    this.selectedCards = [];
    this.reportMapDataset = [];
    this.hasDvr = false;
    this.hasRequestMediaAddon = false;

    this.mapModeList = [
      { type: 'route', icon: 'gs_pin_grid', description: 'Reconstrução de Rota', selected: true },
      { type: 'all', icon: 'gs_mapmode', description: 'Todos os Pontos', selected: false },
      { type: 'heat', icon: 'gs_heatmap', description: 'Mapa de Calor', selected: false },
    ];

    this.cardModeList = [
      { type: 'resume', icon: 'gs_map', description: 'Resumo da utilização', selected: true },
      {
        type: 'driver',
        icon: 'gs_driver',
        description: 'Identificação de condutor',
        selected: false,
      },
    ];

    this.indexationDriverController = {
      searchDriver: '',
      driverList: [{ id: null, description: 'Não identificado' }, { holder: true }],
      driverImagePopup: {
        showIndex: 0,
        detailList: [],
        shouldReload: false,
      },
    };

    this.indexationMultiDriverController = {
      optionsList: [],
      searchText: '',
      limitIndex: 0,
    };

    moment.locale('pt-BR');
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      requestDataset: this.requestDataset.bind(this),
    });

    if (!this.isVideoTelemetry) {
      this.$.removeAttribute('has-dvr');
      this.hasDvr = false;
    } else {
      this.hasDvr = this.$.hasAttribute('has-dvr');
    }
    this.hasRequestMediaAddon = this.modules.includes('REQUISICAO_MIDIA');

    const matchTablet = window.matchMedia('(max-width: 768px)');
    const matchTabletEvt = evt =>
      evt.matches && this.stateConfig.viewMode == 'split' ? this.changeView('grid') : null;
    matchTablet.addListener(matchTabletEvt);

    this.$.addEventListener('share', this.onShareEvent);
    this.$.addEventListener('delete', this.onDeleteEvent);
    this.$.addEventListener('move', this.onMoveEvent);

    this.$scope.$on('goToLink', this._goToLink.bind(this));
    this.$scope.$on('UPDATE_ROUTE', () => this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' }));
    this.$scope.$watch(() => this.reportDataset, this.__reportDatasetChanged.bind(this));

    this.$scope.$on('updateTimelineSlider', (evt, evtParams) =>
      this._updateTimelineSlider(evtParams.value),
    );

    if (this.stateConfig.viewMode) {
      this.changeView(this.stateConfig.viewMode);
    } else {
      this.changeView('grid');
    }

    if (this.stateConfig.isCustomReport && this.stateConfig.customReportId) {
      this.$scope.$on('getDatasetReady', () => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: true },
            bubbles: true,
            composed: true,
          }),
        );

        const showReportNotFoundMessage = () => {
          this.toastText = 'O relatório selecionado não existe ou foi removido';
          this.$.querySelector('power-toast#report-toast').toggle(true);
        };

        this.reportServices
          .getUserReportConfiguration({
            _id: this.stateConfig.customReportId,
          })
          .then(success => {
            if (success.status && success.status == 200 && success.data.data) {
              const { data } = success.data;

              if (data) {
                this.userReportConfiguration = {
                  ...data,
                  filterConfig: JSON.parse(data.filterConfig),
                  gridConfig: JSON.parse(data.gridConfig),
                };

                return;
              }
            }
            this.userReportConfiguration = null;
            showReportNotFoundMessage();
          })
          .catch(() => {
            this.userReportConfiguration = null;
            showReportNotFoundMessage();
          })
          .finally(() => {
            if (!this.stateConfig.filterConfig || this.stateConfig.filterConfig.length == 0) {
              this.$scope.$broadcast('getHeaders', {
                screenName: this.stateConfig.screenName,
                gridName: this.stateConfig.gridName,
                userGridConfig: this.userReportConfiguration
                  ? this.userReportConfiguration.gridConfig
                  : null,
              });

              this._getStateConfig();
            } else {
              const stateGridConfig = this.stateConfig.gridConfig;

              this.$scope.$broadcast('getHeaders', {
                screenName: this.stateConfig.screenName,
                gridName: this.stateConfig.gridName,
                page: !this.userReportConfiguration ? stateGridConfig.page : undefined,
                pageSize: !this.userReportConfiguration ? stateGridConfig.pageSize : undefined,
                userGridConfig:
                  !this.isDrillDownRoute && !!this.userReportConfiguration
                    ? this.userReportConfiguration.gridConfig
                    : undefined,
              });
              this.$scope.$broadcast('getDataset', {
                filter: {
                  conditions: this.stateConfig.filterConditions,
                },
                isPaginated: stateGridConfig.backPagination,
                ...this.stateConfig.getDataFixedParams,
              });

              document
                .querySelector('#app-component')
                .querySelector('power-filter-menu')
                .validateFilters();
            }
          });
      });
    } else if (!this.stateConfig.filterConfig || this.stateConfig.filterConfig.length == 0) {
      this.$scope.$on('getDatasetReady', () => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: true },
            bubbles: true,
            composed: true,
          }),
        );
        this.$scope.$broadcast('getHeaders', {
          screenName: this.stateConfig.screenName,
          gridName: this.stateConfig.gridName,
        });
        this._getStateConfig();
      });
    } else {
      this.$scope.$on('getDatasetReady', () => {
        this.$scope.$broadcast('getHeaders', {
          screenName: this.stateConfig.screenName,
          gridName: this.stateConfig.gridName,
          page: this.stateConfig.gridConfig.page,
          pageSize: this.stateConfig.gridConfig.pageSize,
        });

        this.$scope.$broadcast('getDataset', {
          filter: {
            conditions: this.stateConfig.filterConditions,
          },
          navigation: {
            date: this.stateConfig.navigation.date[this.stateConfig.navigation.date.length - 1],
          },
          isPaginated: this.stateConfig.gridConfig.backPagination,
          ...this.stateConfig.getDataFixedParams,
        });

        document.querySelector('#app-component').querySelector('power-filter-menu');
      });
    }

    if (this.$.querySelector('#report-body-card')) {
      this.$.querySelector('#report-body-card').addEventListener(
        'selectRow',
        this.__onCardListSelectRow.bind(this),
      );

      this.$.querySelector('#report-body-card').addEventListener(
        'requestSyncVisualization',
        this.__onRequestSyncVisualization.bind(this),
      );
    }

    if (this.$.querySelector('#report-body-grid')) {
      this.$.querySelector('#report-body-grid').addEventListener(
        'requestSyncVisualization',
        this.__onRequestSyncVisualization.bind(this),
      );
    }

    this._loadMultiDriverIndexationOptionsList();
  }

  _selectMapMode(mapMode) {
    if (mapMode.selected) return;
    const mapComponent = this.$.querySelector('#report-body-map');
    this.mapModeList = this.mapModeList.map(mode => ({
      ...mode,
      selected: mode.type == mapMode.type,
    }));
    switch (mapMode.type) {
      case 'route':
        mapComponent.removeLayers([
          'allOn',
          'allOff',
          'allIddle',
          'allInvalid',
          'heatLayer',
          'stepMarker',
          'routeMarkers',
          'routeLinestrings',
        ]);
        mapComponent.toggleTimelineControl(true);

        this._renderRouteMode(this.reportMapDataset).then(() => {
          if (!this.timelineSlider)
            // eslint-disable-next-line no-unused-expressions
            this.reportMapDataset.length > 0
              ? this._createTimelineSlider(this.reportMapDataset.length - 1)
              : null;
          else
            this._updateTimelineSlider(
              this.reportMapDataset.length - 1,
              this.reportMapDataset.length - 1,
            );
        });
        break;
      case 'all':
        mapComponent.removeLayers(['heatLayer', 'stepMarker', 'routeMarkers', 'routeLinestrings']);
        mapComponent.toggleTimelineControl(false);
        this._renderAllMode(this.reportMapDataset);
        break;
      case 'heat':
        mapComponent.removeLayers([
          'allOn',
          'allOff',
          'allIddle',
          'allInvalid',
          'stepMarker',
          'routeMarkers',
          'routeLinestrings',
        ]);
        mapComponent.toggleTimelineControl(false);
        this._renderHeatMode(this.reportMapDataset);
        break;
      case 'step':
        break;
      default:
        break;
    }
  }

  _getSelectedMapMode() {
    return this.mapModeList.find(mode => mode.selected);
  }

  _selectCardMode(cardMode) {
    if (cardMode.selected) return;
    const cardComponent = this.$.querySelector('power-card-utilization-list');

    this.cardModeList = this.cardModeList.map(mode => ({
      ...mode,
      selected: mode.type == cardMode.type,
    }));

    switch (cardMode.type) {
      case 'resume':
        cardComponent.setShowDetails({ showDetails: true });
        break;
      case 'driver':
        cardComponent.setShowDetails({ showDetails: false });
        break;
      default:
        break;
    }
  }

  _getSelectedCardMode() {
    return this.cardModeList.find(mode => mode.selected);
  }

  _evtRefreshGrid() {
    this.toastText = 'Alteração de condutor enviado para processamento com sucesso!';
    this.$.querySelector('power-toast#report-toast').toggle(true);

    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    this.requestDataset(true);
  }

  async _renderAllMode(dataset) {
    const mapComponent = this.$.querySelector('#report-body-map');

    const parsedDataset = dataset.reduce((acc, data) => {
      switch (data.statusIgnicao) {
        case 1:
          return Object.assign(acc, {
            on: acc.on.concat(
              Object.assign(data, {
                color: '#4AAE4E',
                icon: data.iconeVeiculo || 'directions_car',
                markerIcon: 'MarkerIcon',
              }),
            ),
          });
        case 2:
          return Object.assign(acc, {
            iddle: acc.iddle.concat(
              Object.assign(data, {
                color: '#DECE00',
                icon: data.iconeVeiculo || 'directions_car',
                markerIcon: 'MarkerIcon',
              }),
            ),
          });
        case 3:
          return Object.assign(acc, {
            off: acc.off.concat(
              Object.assign(data, {
                color: '#980A1A',
                icon: data.iconeVeiculo || 'directions_car',
                markerIcon: 'MarkerIcon',
              }),
            ),
          });
        default:
          return Object.assign(acc, {
            invalid: acc.invalid.concat(
              Object.assign(data, {
                color: '#949494',
                icon: data.iconeVeiculo || 'directions_car',
                markerIcon: 'MarkerIcon',
              }),
            ),
          });
      }
    }, Object({ on: [], iddle: [], off: [], invalid: [] }));

    await mapComponent.renderDataset({
      dataset: parsedDataset.on,
      layerName: 'allOn',
      type: 'Cluster',
      useCluster: true,
      clusterColor: '#4AAE4E',
    });

    await mapComponent.renderDataset({
      dataset: parsedDataset.iddle,
      layerName: 'allIddle',
      type: 'Cluster',
      useCluster: true,
      clusterColor: '#DECE00',
    });

    await mapComponent.renderDataset({
      dataset: parsedDataset.off,
      layerName: 'allOff',
      type: 'Cluster',
      useCluster: true,
      clusterColor: '#980A1A',
    });

    await mapComponent.renderDataset({
      dataset: parsedDataset.invalid,
      layerName: 'allInvalid',
      type: 'Cluster',
      useCluster: true,
      clusterColor: '#949494',
    });

    mapComponent.resizeMap();
    mapComponent.fitLayers(['allOn', 'allIddle', 'allOff', 'allInvalid']);
  }

  async _renderHeatMode(dataset) {
    const mapComponent = this.$.querySelector('#report-body-map');
    await mapComponent.renderDataset({ dataset, layerName: 'heatLayer', type: 'HeatLayer' });
    mapComponent.resizeMap();
    mapComponent.fitLayers([], 'heatLayer');
  }

  async _renderStepMode(position) {
    const index = position == 0 ? 0 : position - 1;
    const mapComponent = this.$.querySelector('#report-body-map');
    this.mapModeList = this.mapModeList.map(mode =>
      mode.type == 'step' ? { ...mode, step: index } : mode,
    );
    const dataPosition = this.reportDataset[index];
    this.selectedVehicle = dataPosition;

    switch (dataPosition.statusIgnicao) {
      case 1:
        Object.assign(dataPosition, {
          color: '#4AAE4E',
          icon: dataPosition.iconeVeiculo || 'directions_car',
          markerIcon: 'MarkerIcon',
        });
        break;
      case 2:
        Object.assign(dataPosition, {
          color: '#DECE00',
          icon: dataPosition.iconeVeiculo || 'directions_car',
          markerIcon: 'MarkerIcon',
        });
        break;
      case 3:
        Object.assign(dataPosition, {
          color: '#980A1A',
          icon: dataPosition.iconeVeiculo || 'directions_car',
          markerIcon: 'MarkerIcon',
        });
        break;
      default:
        Object.assign(dataPosition, {
          color: '#949494',
          icon: dataPosition.iconeVeiculo || 'directions_car',
          markerIcon: 'MarkerIcon',
        });
        break;
    }

    await mapComponent.renderDataset({
      dataset: [dataPosition],
      layerName: 'stepMarker',
      type: 'MarkerFeatureGroup',
    });

    mapComponent.fitLayers(['stepMarker']);
    mapComponent.openPopup(0, 'stepMarker');
  }

  async _renderRouteMode(dataset) {
    if (dataset.length == 0) {
      const mediaRequestComponent = this.$.querySelector('#report-body-media');
      await mediaRequestComponent.clearDataset();
      return;
    }

    const serviceMatchRouteIsActive = await this._hasServiceMatchRouteActive(dataset[0].placa);
    const { ativo } = serviceMatchRouteIsActive.data.data
      ? serviceMatchRouteIsActive.data.data
      : { ativo: false };

    if (ativo) {
      const gpxTemplate = (arr = []) => `<?xml version="1.0"?>
        <gpx version="1.0"
          xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
          xmlns="http://www.topografix.com/GPX/1/0"
          xsi:schemaLocation="http://www.topografix.com/GPX/1/0 http://www.topografix.com/GPX/1/0/gpx.xsd"
        >
          <trk>
            <trkseg>
              ${arr
                .map(
                  item =>
                    `<trkpt lat="${item.latitude.replace(',', '.')}" lon="${item.longitude.replace(
                      ',',
                      '.',
                    )}"/>`,
                )
                .join('\n        ')}
            </trkseg>
          </trk>
        </gpx>
      `;

      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: true },
          bubbles: true,
          composed: true,
        }),
      );

      const request = arr =>
        this.$http({
          url: 'https://rme.cit.api.here.com/2/matchroute.json?routemode=car&app_id=Iajt61WMPoEvbbMdvMH8&app_code=Z7ncDhO8GxIIHGGDmzCkWQ',
          method: 'POST',
          headers: {
            'Content-Type': 'text/plain',
            Accept: '*/*',
          },
          data: gpxTemplate(arr),
        }).then(result => {
          const markers = result.data.TracePoints.map((tracePoint, index, tracePoints) => ({
            ...dataset[index],
            latitude:
              tracePoint.confidenceValue > 0 ? tracePoint.latMatched : dataset[index].latitude,
            longitude:
              tracePoint.confidenceValue > 0 ? tracePoint.lonMatched : dataset[index].longitude,
            first: index === 0,
            last: index === tracePoints.length - 1,
            markerIcon: 'RouteIcon',
          }));

          const linestrings = [
            {
              geoJson: {
                type: 'LineString',
                coordinates: result.data.RouteLinks.reduce(
                  (acc, routeLink) => [...acc, ...routeLink.shape.split(' ')],
                  [],
                ).reduce((acc, routeLink, index, routeLinks) => {
                  if (index % 2 != 0) {
                    acc.push([routeLink, routeLinks[index - 1]]);
                  }
                  return acc;
                }, []),
              },
            },
          ];

          return {
            markers,
            linestrings,
          };
        });

      const { markers, linestrings } = await request(dataset);

      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: false },
          bubbles: true,
          composed: true,
        }),
      );

      this.$.querySelector('#report-body-map').setTimelineDataset({
        markers,
        linestrings,
      });
    } else {
      this.$.querySelector('#report-body-map').setTimelineDataset(
        dataset.reduce(
          (acc, ele, index, arr) => {
            if (index == 0)
              return Object.assign(acc, {
                markers: acc.markers.concat({
                  ...ele,
                  first: true,
                  last: false,
                  markerIcon: 'RouteIcon',
                }),
              });
            return Object.assign(acc, {
              markers: acc.markers.concat({
                ...ele,
                first: false,
                last: index == arr.length - 1,
                markerIcon: 'RouteIcon',
              }),
              linestrings: acc.linestrings.concat({
                geoJson: {
                  type: 'LineString',
                  coordinates: [
                    [
                      this._formatLatLng(arr[index - 1].longitude),
                      this._formatLatLng(arr[index - 1].latitude),
                    ],
                    [this._formatLatLng(ele.longitude), this._formatLatLng(ele.latitude)],
                  ],
                  properties: { dataHora: ele.dataHora },
                },
              }),
            });
          },
          { markers: [], linestrings: [] },
        ),
      );
    }

    if (dataset.length > 0 && this.hasDvr) {
      const gridComponent = this.$.querySelector('power-grid-utilization');
      const activeRow = gridComponent.getActiveRow();

      const mediaRequestComponent = this.$.querySelector('#report-body-media');
      await mediaRequestComponent.setup({
        vehicleId: activeRow.veiculoId,
        vehiclePlate: activeRow.placa,
        startingDateTime: activeRow.dataHoraIgnicaoLigada,
        startingAddress: activeRow.enderecoPosicaoInicial,
        endingDateTime: activeRow.dataHoraIgnicaoDesligada,
        endingAddress: activeRow.enderecoPosicaoFinal,
        lastPositionDateTime: activeRow.dataHoraUltimaPosicao,
        compatibility: activeRow.compatibilidadeMidiaPorPedido,
        historicalVehicle: activeRow.veiculoHistorico,
        dataset,
      });

      this._requestUpdate();
    }
  }

  _createTimelineSlider(maxRange) {
    if (maxRange > 0) {
      this.timelineSlider = noUiSlider.create(this.$.querySelector('.timeline-slider'), {
        start: maxRange,
        connect: [true, false],
        range: { min: 0, max: maxRange },
        step: 1,
        behaviour: 'drag',
        tooltips: {
          from: value => parseInt(value, 10),
          to: value => {
            const index = value.toFixed(0);
            const lastIndex = this.reportMapDataset.length - 1;
            const data = this.reportMapDataset[index > lastIndex ? lastIndex : index];
            // Note: the Math.abs converts from negative to positive
            if (data) {
              const kmDiff =
                this.reportMapDataset.length > 0
                  ? Math.abs(this.reportMapDataset[0].odometro - data.odometro)
                  : 0;
              const dateDiff = data.dataHora - this.reportMapDataset[0].dataHora;
              const dateDiffHour = `${dateDiff / 3600000}`.split('.')[0];
              const dateDiffMinutes = `${dateDiff / 60000 - dateDiffHour * 60}`.split('.')[0];
              return `
                <div>
                  <i class="material-icons">gs_date</i>
                  <span>${new Date(data.dataHora).toLocaleString('pt-BR', {
                    timeZone: 'America/Sao_Paulo',
                  })}</span>
                </div>
                <div>
                  <i class="material-icons">gs_speed</i>
                  <span>${kmDiff.toFixed(2)} km percorridos</span>
                </div>
                <div>
                  <i class="material-icons">access_time</i>
                  <span>
                    ${dateDiffHour < 10 ? 0 : ''}${dateDiffHour}:${
                dateDiffMinutes < 10 ? 0 : ''
              }${dateDiffMinutes} percorridos
                  </span>
                </div>
              `;
            }
            return '';
          },
        },
      });
      this.timelineSlider.on('update', values =>
        this.$.querySelector('#report-body-map').updateTimelinePosition({
          pathPosition: parseInt(values[0], 10),
        }),
      );
    }
  }

  _updateTimelineSlider(value, maxRange) {
    if (maxRange) this.timelineSlider.updateOptions({ range: { min: 0, max: maxRange } });
    this.timelineSlider.set(value);
  }

  async _hasServiceMatchRouteActive(plate) {
    return this.reportServices.genericRequest('/TelematicsReport/ServiceMatchRouteIsActive', {
      request: {
        plate,
      },
    });
  }

  _formatLatLng(str) {
    return str.replace(',', '.');
  }

  _parseDate(date) {
    return new Date(date.setHours(date.getHours() - 3));
  }

  _toDate(date) {
    return moment(date).utcOffset(-3 - moment(date).utcOffset() / 60)._d;
  }

  _showDriverImagePopup({ show, data }) {
    // @NOTE: se for identificação diferente de reconhecimento facial, a função é encerrada.
    if (data && data.tipoIdentificacaoCondutor != 3 && data.tipoIdentificacaoCondutor != 4) {
      return;
    }

    this.indexationDriverController.searchDriver = '';

    if (show) {
      Object.assign(this.indexationDriverController.driverImagePopup, {
        showIndex: 0,
        retry: true,
        detailList: data._driverFaces.data.map(detail =>
          Object.assign(detail, {
            latitude: data.latitude,
            longitude: data.longitude,
            driverList: this._parseDriverList(detail),
            selectedDriver: {
              id: detail.mrvTmtId || null,
            },
            previousSelectedDriver: detail.mrvTmtId || null,
          }),
        ),
      });

      this.$.querySelector('#power-popup-driver-indexation').toggle();

      if (
        this.indexationDriverController.driverImagePopup.detailList.length > 0 &&
        this.indexationDriverController.driverImagePopup.detailList[0].image
      ) {
        const drawImageInterval = setInterval(() => {
          if (document.querySelector('#driver-image-popup-body').clientWidth) {
            this._drawImage(
              this.indexationDriverController.driverImagePopup.detailList[0].image.isUrl
                ? this.indexationDriverController.driverImagePopup.detailList[0].image.result
                : `data:image/jpeg;base64,${this.indexationDriverController.driverImagePopup.detailList[0].image.result}`,
              document.createElement('canvas'),
              this.$.querySelector('#driver-image-popup-body'),
            );
            if (this.isTrainingMode) {
              const canvas = this.$.querySelector('#driver-image-popup-body > canvas');
              canvas.classList.add('blurred');
            }

            clearInterval(drawImageInterval);
          }
        }, 250);
      }
    } else {
      if (this.indexationDriverController.driverImagePopup.shouldReload) {
        this.requestDataset(true);
        this.indexationDriverController.driverImagePopup.shouldReload = false;
      }

      this.$.querySelector('#power-popup-driver-indexation').toggle();
    }
  }

  _showEventMedias({ data, mediaType, mediaByEvent, mediaByRequest }) {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    const dataInitialDate = moment(data.dataHoraIgnicaoLigada);
    const dataEndDate = moment(data.dataHoraIgnicaoDesligada || data.dataHoraUltimaPosicao);

    this.$http({
      url: `${this.urlApi}/Utilizations/GetMediasByUtilization`,
      method: 'POST',
      data: {
        request: {
          veiculoId: data.veiculoId,
          dataHoraInicial: dataInitialDate,
          dataHoraFinal: dataEndDate,
          tipoMidia: mediaType === 'photo' ? 2 : 1,
          midiaPorEvento: mediaByEvent,
          midiaPorPedido: mediaByRequest,
        },
      },
    })
      .then(
        result => {
          if (!result.data.hasError && result.data.data) {
            const response = result.data.data;
            const mediaList = response.data.filter(
              media =>
                (mediaType == 'video' && media.tipoMidia == 'VIDEO') ||
                (mediaType == 'photo' && media.tipoMidia != 'VIDEO'),
            );

            this.$.querySelector('#popup-view-media').viewMediaList(mediaList);
          } else {
            Object.assign(this, {
              toastText: 'Ops, não foi possível carregar as mídias da utilização',
            });
            this.$.querySelector('power-toast#report-toast').toggle(true);
          }
        },
        () => {
          Object.assign(this, {
            toastText: 'Ops, não foi possível carregar as mídias da utilização',
          });
          this.$.querySelector('power-toast#report-toast').toggle(true);
        },
      )
      .finally(() => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
      });
  }

  _parseDriverList(detail) {
    const driverList = Object.clone([], this.indexationDriverController.driverList);
    if (driverList.find(driver => driver.id == detail.mrvTmtId)) return driverList;
    return driverList.map(driver =>
      !driver.holder
        ? driver
        : Object.assign(driver, {
            id: detail.mrvTmtId,
            description: detail.tmtNome,
          }),
    );
  }

  _getDriverDescription(detail, defaultText) {
    if (!detail) return defaultText;
    const selectedDriver = detail.driverList.filter(
      driver => driver.id == detail.selectedDriver.id,
    )[0];
    if (selectedDriver) return selectedDriver.description;
    return defaultText;
  }

  _drawImage(url, canvas, canvasContainer) {
    this.$.querySelector('#driver-image-popup-loader').toggle(true);
    if (canvasContainer.querySelector('canvas'))
      canvasContainer.replaceChild(canvas, canvasContainer.querySelector('canvas'));
    else canvasContainer.appendChild(canvas);
    Object.assign(canvas, {
      width: canvasContainer.clientWidth,
      height: canvasContainer.clientHeight,
    });

    const image = new Image();
    const ctx = canvas.getContext('2d');
    let lastX = canvas.width / 2;
    let lastY = canvas.height / 2;
    let dragStart;
    let dragged;

    const redraw = function redraw() {
      const p1 = ctx.transformedPoint(0, 0);
      const p2 = ctx.transformedPoint(canvas.width, canvas.height);
      ctx.clearRect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y);

      if (canvas.width > image.width) {
        ctx.drawImage(image, 0, 0);
      } else {
        ctx.drawImage(image, 0, 0, canvas.width, (canvas.width * image.height) / image.width);
      }

      ctx.save();
    };
    const zoom = function zoom(clicks) {
      const pt = ctx.transformedPoint(lastX, lastY);
      ctx.translate(pt.x, pt.y);
      // eslint-disable-next-line no-restricted-properties
      const factor = Math.pow(1.1, clicks);
      ctx.scale(factor, factor);
      ctx.translate(-pt.x, -pt.y);
      redraw();
    };
    const handleScroll = function handleScroll(evt) {
      // eslint-disable-next-line no-nested-ternary
      const delta = evt.wheelDelta ? evt.wheelDelta / 40 : evt.detail ? -evt.detail : 0;
      if (delta) zoom(delta);
      return evt.preventDefault() && false;
    };
    const handleMouseUp = function handleMouseUp(evt) {
      dragStart = null;
      if (!dragged) zoom(evt.shiftKey ? -1 : 1);
    };
    const handleMouseDown = function handleMouseDown(evt) {
      // eslint-disable-next-line no-multi-assign
      document.body.style.mozUserSelect = 'none';
      lastX = evt.offsetX || evt.pageX - canvas.offsetLeft;
      lastY = evt.offsetY || evt.pageY - canvas.offsetTop;
      dragStart = ctx.transformedPoint(lastX, lastY);
      dragged = false;
    };
    const handleMouseMove = function handleMouseMove(evt) {
      lastX = evt.offsetX || evt.pageX - canvas.offsetLeft;
      lastY = evt.offsetY || evt.pageY - canvas.offsetTop;
      dragged = true;
      if (dragStart) {
        const pt = ctx.transformedPoint(lastX, lastY);
        ctx.translate(pt.x - dragStart.x, pt.y - dragStart.y);
        redraw();
      }
    };
    // eslint-disable-next-line no-shadow
    const trackTransforms = function trackTransforms(ctx) {
      const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      const savedTransforms = [];
      let xform = svg.createSVGMatrix();
      ctx.getTransform = () => xform;

      const { save } = ctx;
      ctx.save = () => {
        savedTransforms.push(xform.translate(0, 0));
        return save.call(ctx);
      };

      const { restore } = ctx;
      ctx.restore = () => {
        xform = savedTransforms.pop();
        return restore.call(ctx);
      };

      const { scale } = ctx;
      ctx.scale = (sx, sy) => {
        xform = xform.scaleNonUniform(sx, sy);
        return scale.call(ctx, sx, sy);
      };

      const { rotate } = ctx;
      ctx.rotate = radians => {
        xform = xform.rotate((radians * 180) / Math.PI);
        return rotate.call(ctx, radians);
      };

      const { translate } = ctx;
      ctx.translate = (dx, dy) => {
        xform = xform.translate(dx, dy);
        return translate.call(ctx, dx, dy);
      };

      const { transform } = ctx;
      ctx.transform = (a, b, c, d, e, f) => {
        const m2 = svg.createSVGMatrix();
        Object.assign(m2, { a, b, c, d, e, f });
        xform = xform.multiply(m2);
        return transform.call(ctx, a, b, c, d, e, f);
      };

      const { setTransform } = ctx;
      ctx.setTransform = (a, b, c, d, e, f) => {
        Object.assign(xform, { a, b, c, d, e, f });
        return setTransform.call(ctx, a, b, c, d, e, f);
      };

      const pt = svg.createSVGPoint();
      ctx.transformedPoint = (x, y) => {
        Object.assign(pt, { x, y });
        return pt.matrixTransform(xform.inverse());
      };
    };

    image.onload = () => {
      trackTransforms(ctx);
      redraw();
      setTimeout(() => zoom(0));
      this.$.querySelector('#driver-image-popup-loader').toggle(false);
    };

    image.src = url;

    canvas.addEventListener('mousedown', handleMouseDown, false);
    canvas.addEventListener('mousemove', handleMouseMove, false);
    canvas.addEventListener('mouseup', handleMouseUp, false);

    canvas.addEventListener('DOMMouseScroll', handleScroll, false);
    canvas.addEventListener('mousewheel', handleScroll, false);
  }

  _focusSearch() {
    setTimeout(() => this.$.querySelector('search-slot input').focus(), 250);
  }

  _changeDriver(index) {
    this.indexationDriverController.driverImagePopup.showIndex = index;

    this._drawImage(
      this.indexationDriverController.driverImagePopup.detailList[index].image.isUrl
        ? this.indexationDriverController.driverImagePopup.detailList[index].image.result
        : `data:image/jpeg;base64,${this.indexationDriverController.driverImagePopup.detailList[index].image.result}`,
      document.createElement('canvas'),
      this.$.querySelector('#driver-image-popup-body'),
    );
  }

  _setDriver(selectedDriver) {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );
    const driverImageDetail =
      this.indexationDriverController.driverImagePopup.detailList[
        this.indexationDriverController.driverImagePopup.showIndex
      ];

    this.$http({
      url: `${this.urlApi}/FleetCam/IdentifyDriver`,
      method: 'POST',
      data: {
        request: {
          tmtId: selectedDriver.id,
          tmsId: driverImageDetail.tmsId,
          mrvId: driverImageDetail.mrvId,
          mrvVeiId: driverImageDetail.mrvVeiId,
          mrvDataHoraExibicao: new Date(driverImageDetail.mrvDataHoraExibicao),
          evento: driverImageDetail.evento,
          latitude: driverImageDetail.latitude,
          longitude: driverImageDetail.longitude,
          imageName: driverImageDetail.driverFaces,
          bucket: driverImageDetail.bucketDriverFaces,
          tmtUlid: selectedDriver.ulid,
        },
      },
    })
      .then(
        () => {
          this.indexationDriverController.driverImagePopup.shouldReload = true;
          Object.assign(this, { toastText: 'Condutor selecionado' });
          this.$.querySelector('power-toast#report-toast').toggle(true);
          this._showDriverImagePopup({ show: false });
        },
        () => {
          Object.assign(this, {
            toastText: 'Ops, não foi possível selecionar o Condutor',
          });
          this.$.querySelector('power-toast#report-toast').toggle(true);
        },
      )
      .finally(() =>
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        ),
      );
  }

  _selectDriver(driverId, driverUlid, showIndex) {
    this.indexationDriverController.driverImagePopup.detailList[showIndex].selectedDriver = {
      id: driverId,
      ulid: driverUlid,
    };
  }
  /* */

  /* Public */
  changePage(page) {
    this.$scope.$broadcast('changePage', {
      page,
      payload: {
        filter: {
          conditions: this.stateConfig.filterConditions,
        },
        navigation: {
          date: this.stateConfig.navigation.date[this.stateConfig.navigation.date.length - 1],
        },
        isPaginated: this.stateConfig.gridConfig.backPagination,
        ...this.stateConfig.getDataFixedParams,
      },
    });
  }

  changeView(viewMode) {
    this.stateConfig.viewMode = viewMode;
    this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' });
    const mapComponent = this.$.querySelector('#report-body-map');
    switch (viewMode) {
      case 'map':
        this.$.setAttribute('map-view', '');
        this.$.removeAttribute('card-view');
        this.$.removeAttribute('grid-view');
        this.$.removeAttribute('split-view');
        this.$.removeAttribute('medias-view');
        if (mapComponent.resizeMap) mapComponent.resizeMap();
        break;
      case 'card':
        this.$.setAttribute('card-view', '');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('grid-view');
        this.$.removeAttribute('split-view');
        this.$.removeAttribute('medias-view');
        if (mapComponent.resizeMap) mapComponent.resizeMap();
        break;
      case 'split':
        this.$.setAttribute('split-view', '');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('card-view');
        this.$.removeAttribute('grid-view');
        this.$.removeAttribute('medias-view');
        if (mapComponent.resizeMap) mapComponent.resizeMap();
        break;
      case 'medias':
        this.$.setAttribute('medias-view', '');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('card-view');
        this.$.removeAttribute('grid-view');
        this.$.removeAttribute('split-view');
        if (mapComponent.resizeMap) mapComponent.resizeMap();
        break;
      default:
        this.$.setAttribute('grid-view', '');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('card-view');
        this.$.removeAttribute('split-view');
        this.$.removeAttribute('medias-view');
        break;
    }
  }

  changePageSize(pageSize) {
    this.$timeout(() => {
      this.$scope.$broadcast('changePageSize', {
        pageSize,
        payload: {
          filter: {
            conditions: this.stateConfig.filterConditions,
          },
          navigation: {
            date: this.stateConfig.navigation.date[this.stateConfig.navigation.date.length - 1],
          },
          isPaginated: this.stateConfig.gridConfig.backPagination,
          ...this.stateConfig.getDataFixedParams,
        },
      });
    });
  }

  requestDataset(keepPage) {
    const [calendarFilter] = this.stateConfig.filterConfig.filter(
      filter => filter.type === 'calendar',
    );

    if (
      this.stateConfig.navigation.date.length === 0 ||
      calendarFilter.condition.value[0] !== this.stateConfig.navigation.date[0].startDate ||
      calendarFilter.condition.value[0] !== this.stateConfig.navigation.date[0].endDate
    ) {
      this.stateConfig.navigation = {
        date: [
          {
            endDate: calendarFilter.condition.value[1],
            startDate: calendarFilter.condition.value[0],
            granularity: 'mes',
          },
        ],
        dateMinPath: 0,
      };
    }

    const payload = {
      filter: {
        conditions: this.stateConfig.filterConditions,
      },
      navigation: {
        date: this.stateConfig.navigation.date[this.stateConfig.navigation.date.length - 1],
      },
      isPaginated: this.stateConfig.gridConfig.backPagination,
    };

    if (!keepPage) payload.page = 1;

    this.$scope.$broadcast(
      'getDataset',
      Object.assign(payload, this.stateConfig.getDataFixedParams),
    );
  }
  /* */

  /* Private */
  async _callAction(action) {
    const gridComponent = this.$.querySelector('power-grid-utilization');
    const cardComponent = this.$.querySelector('#report-body-card');

    switch (action.actionType) {
      case 'selectAllOrNone':
        await gridComponent.selectAllRowsDriverFacesOrNone();
        this.selectedCards = await gridComponent.getSelectedRowsDriverFaces();
        cardComponent.refreshCards();
        this._requestUpdate();
        break;
      case 'toggleSelection':
        await gridComponent.toggleSelectedRowsDriverFaces();
        this.selectedCards = await gridComponent.getSelectedRowsDriverFaces();
        cardComponent.refreshCards();
        this._requestUpdate();
        break;
      case 'multiIndexation':
        this._toggleMultiDriverIndexationPopup();
        break;
      default:
        super._callAction(action);
        break;
    }
  }

  _toggleMultiDriverIndexationPopup() {
    const controller = this.indexationMultiDriverController;
    controller.searchText = '';
    controller.limitIndex = 0;

    this.$.querySelector('#power-popup-multi-indexation').toggle();
  }

  _loadMultiDriverIndexationOptionsList() {
    const controller = this.indexationMultiDriverController;
    controller.optionsList = [];

    this.$http({
      url: `${this.urlApi}/Driver/GetNames`,
      method: 'POST',
    })
      .then(result => result.data.data)
      .then(result =>
        Object.assign(
          this.indexationDriverController.driverList,
          this.indexationDriverController.driverList.concat(result),
        ),
      )
      .then(optionsList => {
        controller.optionsList = optionsList.map(option => ({
          id: option.id,
          description: option.description,
          ulid: option.extraId,
          images: [],
          selected: false,
        }));
      });
  }

  _selectMultiDriverOption(optionItem) {
    const controller = this.indexationMultiDriverController;

    controller.optionsList = controller.optionsList.map(option => ({
      ...option,
      selected: option.id === optionItem.id,
    }));
  }

  _requestMultiDriverIndexation() {
    const controller = this.indexationMultiDriverController;

    const optionSelected = controller.optionsList.find(item => item.selected);
    if (!optionSelected && this.selectedCards.length === 0) return;

    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    const items = [];
    this.selectedCards.forEach(card => {
      card._driverFaces.data.forEach(item => {
        items.push({
          tmtId: item.mrvTmtId,
          tmsId: item.tmsId,
          mrvId: item.mrvId,
          mrvVeiId: item.mrvVeiId,
          mrvDataHoraExibicao: new Date(item.mrvDataHoraExibicao),
          evento: item.evento,
          imageName: item.driverFaces,
          bucket: item.bucketDriverFaces,
        });
      });
    });

    const selectedDriver = controller.optionsList.find(item => item.selected);

    this.$http({
      url: `${this.urlApi}/FleetCam/IdentifyDrivers`,
      method: 'POST',
      data: {
        request: {
          driverId: selectedDriver.id,
          tmtUlid: selectedDriver.ulid,
          list: items,
        },
      },
    }).then(
      () => {
        this.$.querySelector('#power-popup-multi-indexation').toggle();
        this.requestDataset(true);
      },
      () => {
        this.$.querySelector('#power-popup-multi-indexation').toggle();
        this.toastText = 'Não foi possivel fazer a identificação';
        this.$.querySelector('power-toast#report-toast').toggle(true);
      },
    );
  }

  _enableRequestMultiDriverIndexation() {
    const controller = this.indexationMultiDriverController;
    return !controller.optionsList.find(item => item.selected);
  }

  _requestUpdate() {
    if (!this.$rootScope.$$phase && !this.$scope.$$phase) {
      this.$scope.$apply();
    }
  }

  async _changeActiveRow(page, rowPosition) {
    const gridElement = this.$.querySelector('power-grid-utilization');

    this.changePage(page);

    gridElement.setActiveRow({ index: rowPosition - 1 });
  }

  _getActiveRow() {
    const gridElement = this.$.querySelector('power-grid-utilization');
    const selectedIndex = gridElement.getActiveRow()?._index || 0;

    return selectedIndex < 0 ? 0 : selectedIndex + 1;
  }

  _toggleNewMediaRequestPopup() {
    this.$.querySelector('#popup-request-new-media').toggle();
  }

  _getMediaType() {
    return this.$.querySelector('power-media-request-utilization').getMediaType();
  }
  /* */

  /* Observers */
  __routeMarkerTapped(evt) {
    this.timelineSlider.set(evt.detail.pathPosition);
  }

  async __reportDatasetChanged(newValue) {
    if (newValue) {
      this.legendList = this.stateConfig.gridConfig.gridHeaders.reduce((acc, ele) => {
        if (ele.icons && ele.icons.every(i => i.description || i.value)) {
          return acc.concat(ele);
        }
        return acc;
      }, []);

      const gridComponent = this.$.querySelector('power-grid-utilization');
      this.selectedCards = await gridComponent.getSelectedRowsDriverFaces();
    }
  }

  __onCardListSelectRow(evt) {
    this.$.querySelector('#report-grid')?.selectRow(evt?.detail?.row);
  }

  async __onGetMapDataset(evt) {
    const { dataset } = evt.detail;
    this.reportMapDataset = dataset;

    this.mapModeList.forEach(mode => {
      mode.selected = false;
    });
  }

  __onResizeEnded() {
    const mapComponent = this.$.querySelector('#report-body-map');
    mapComponent.resizeMap();
  }

  __onGetPositionsStart({ detail }) {
    const { splitPage } = detail;

    if (splitPage) {
      if (window.innerWidth > 768) {
        this.changeView('split');
      } else {
        this.changeView('map');
      }
    }

    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );
  }

  __onGetPositionsEnd() {
    this._selectMapMode(this.mapModeList.find(mode => mode.type === 'route'));

    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: false },
        bubbles: true,
        composed: true,
      }),
    );
  }

  __onCardPicturesClick(evt) {
    const { data } = evt.detail;
    this._showDriverImagePopup({ show: true, data });
  }

  __onGridIdentificationTypeClick(evt) {
    const { data } = evt.detail;

    // @NOTE: se for identificação por IA ou IA Pendente
    if (data.tipoIdentificacaoCondutor == 6 || data.tipoIdentificacaoCondutor == 7) {
      const popupDriverAi = this.$.querySelector('power-popup-driver-ai-tiebreaker');

      if (
        data.condutorId != null ||
        (data.condutorId == null && data.condutorNome != 'Em Processamento')
      ) {
        if (popupDriverAi) {
          const requestInitialDate = this._toDate(new Date(data.dataHoraIgnicaoLigada));
          popupDriverAi.toggle(data.veiculoId, data.placa, requestInitialDate, data.condutorId);
        }
      }
    }
    this._showDriverImagePopup({ show: true, data });
  }

  __onGridEventMediasClick(evt) {
    const { data, mediaType, mediaByEvent, mediaByRequest } = evt.detail;
    this._showEventMedias({ data, mediaType, mediaByEvent, mediaByRequest });
  }

  __onGridDataLimitReached(evt) {
    const { resultLimit } = evt.detail;
    const popupElement = this.$.querySelector('power-popup-data-limit-reached');
    popupElement.setup({ resultLimit });
    popupElement.toggle();
  }

  __onMediaSelected(evt) {
    const midiaData = {
      ...evt.detail.data,
      eventoGerador: evt.detail.data.title,
      dataHoraExibicao: evt.detail.data.rawDateTime,
      tipoMidia: evt.detail.midiaType,
      midiaExpirada: evt.detail.data.unavailable,
    };

    this.$.querySelector('#popup-view-media').viewMediaList([midiaData]);
  }

  __onMediaRequested(evt) {
    const { value, midiaType } = evt.detail;
    const gridComponent = this.$.querySelector('power-grid-utilization');
    const activeRow = gridComponent.getActiveRow();
    gridComponent.updateRequestedMidiaCount(activeRow, value, midiaType);
  }

  async __onCardSelectionClick(evt) {
    const { data } = evt.detail;

    const gridComponent = this.$.querySelector('power-grid-utilization');
    await gridComponent.toggleRowDriverFace(data);

    this.selectedCards = await gridComponent.getSelectedRowsDriverFaces(data);

    this._requestUpdate();
  }
  /* */
}

class PowerReportUtilization {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = PowerReportUtilizationController;
  }
}

angular
  .module('power-report-utilization', [
    'ngRedux',
    'ng-tippy',
    'ng-resize',
    'power-fab',
    'power-popup',
    'power-footer',
    'power-toolbar',
    'power-dropdown',
    'power-pagination',
    'power-map-utilization',
    'power-navigation-date',
    'power-grid-utilization',
    'power-pagination-register',
    'power-card-utilization-list',
    'power-popup-data-limit-reached',
    'power-popup-driver-ai-tiebreaker',
    'power-popup-delete',
    'power-media-request-utilization',
  ])
  .component('powerReportUtilization', new PowerReportUtilization());

export { PowerReportUtilizationController };
