import angular from 'angular';
import * as Comlink from 'comlink';
import 'angular-sanitize';

import '../../directives/ng-resize/ng-resize';
import { PowerGridController } from '../power-grid/power-grid';

import template from './power-grid-vehicles.html';
import './power-grid-vehicles.scss';

class PowerGridVehiclesController extends PowerGridController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$state',
      '$http',
      '$timeout',
      '$filter',
      'commonServices',
      'urlApi',
      '$ngRedux',
    ];
  }

  constructor(
    $element,
    $scope,
    $state,
    $http,
    $timeout,
    $filter,
    commonServices,
    urlApi,
    $ngRedux,
  ) {
    super($element, $scope, $state, $http, $timeout, $filter, commonServices, urlApi, $ngRedux);

    Object.assign(this, {
      $: $element[0],
      $scope,
      $state,
      $http,
      $timeout,
      $filter,
      commonServices,
      urlApi,
      $ngRedux,
    });

    this.__appBehavior = $ngRedux.connect(behavior => {
      const { userId, userEmail, userName, isTrip, isAdm } = behavior.session;
      const currentState = behavior.state.routeList[behavior.state.routeList.length - 1];

      return Object({
        sessionState: { userId, userEmail, userName, isTrip, isAdm },
        currentState: currentState || {},
        stateConfig: currentState ? currentState.stateConfig : {},
      });
    })(this);

    if (this.worker) this.worker.terminate();

    this.worker = new Worker('./power-grid-vehicles.worker.js');
    this.workerService = Comlink.wrap(this.worker);
  }

  /* Lifecycle */
  $onDestroy() {
    super.$onDestroy();

    this.__appBehavior();
  }

  _getTemperatureColor(row) {
    let colorResponse = '';
    switch (row.indicadorTemperatura) {
      case 0:
        colorResponse = '#5CAAFF';
        break;
      case 1:
        colorResponse = '#555555';
        break;
      case 2:
        colorResponse = '#FF4F63';
        break;
      default:
        colorResponse = '#737373';
        break;
    }
    return colorResponse;
  }
  /**/
}

class PowerGridVehicles {
  constructor() {
    this.template = template;
    this.bindings = {
      /* common */
      datasetMethod: '=?',
      isPaginated: '=?',
      page: '=?',
      pageSize: '=?',
      pageRows: '=?',
      lastPage: '=?',
      datasetLength: '=?',
      hasRowSelection: '=?',
      dateGranularity: '=?',
      /* underscore */
      gridHeaders: '=?',
      gridDataset: '=?',
      gridHeadersCategories: '=?',
      sortHeader: '=?',
      sortDirection: '=?',
      selectedRows: '=?',
      /* duble underscore */
      mainHeader: '=?',
      mongoGridId: '=?',
      headerParams: '=?',
      datasetParams: '=?',
    };
    this.controller = PowerGridVehiclesController;
  }
}

angular
  .module('power-grid-vehicles', ['ngSanitize', 'ng-resize'])
  .component('powerGridVehicles', new PowerGridVehicles());
