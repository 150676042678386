import angular from 'angular';

import template from './golfleet-temperature-sensor.html';
import './golfleet-temperature-sensor.scss';

class GolfleetTemperatureSensorController {
  static get $inject() {
    return ['$element', '$scope', '$rootScope', '$http', 'urlApi'];
  }

  constructor($element, $scope, $rootScope, $http, urlApi) {
    Object.assign(this, {
      $: $element[0],
      $scope,
      $rootScope,
      $http,
      urlApi,
    });

    this.resultLimit = '';
    this.lblInfoFirst = '';
    this.lblInfoSecond = '';
    this.defaultHours = [];
    this.arrayComboHoraIni = [];
    this.arrayComboHoraFim = [];

    this.dataIniId = 1;
    this.dataFimId = 1;

    this.arrayEnabledComboOptions = [];
    this.arrayBuzzerComboOptions = [];

    this.weeklyOptions = [];

    this.arrayValue = [];

    this.temperaturaMinima = '';
    this.temperaturaMaxima = '';

    this.buzzerValue = '';

    this.valorMinimo = -22;
    this.valorMaximo = 100;

    this.error = false;

    this.temperatureOptions = [];
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      getValue: this.getValue.bind(this),
      validateHasEmptyParams: this.validateHasEmptyParams.bind(this),
      validateValidContent: this.validateValidContent.bind(this),
      getBuzzerValue: this.getBuzzerValue.bind(this),
    });
    if (this.value === 'Desabilitado') {
      this.arrayValue = '0;;;Desabilitado'.split(';');
    } else {
      this.arrayValue = this.value.split(';').map(item => item);
    }

    if (typeof this.arrayValue !== 'undefined' && this.arrayValue.length === 4) {
      this.resultLimit = this.arrayValue;
      if (
        typeof this.resultLimit[0] !== 'undefined' &&
        this.arrayEnabledComboOptions.length === 0
      ) {
        this.arrayEnabledComboOptions = [
          {
            objectId: '1',
            value: 'Desabilitado',
            selected: this.resultLimit[0] == '0',
          },
          {
            objectId: '2',
            value: 'Habilitado',
            selected: this.resultLimit[0] == '1',
          },
        ];

        if (typeof this.resultLimit[0] !== 'undefined' && this.resultLimit[0] === '1') {
          this.temperaturaMinima = parseInt(this.resultLimit[1]);
          this.temperaturaMaxima = parseInt(this.resultLimit[2]);
        }

        this.buzzerValue = this.resultLimit[3].toString();

        this._comboOptionsClick();
      }
    }
  }

  getValue() {
    const dataDefault = 'Desabilitado';

    let strReturn = '';

    if (this.arrayEnabledComboOptions.length > 0) {
      this.arrayEnabledComboOptions.forEach(item => {
        if (item.selected && item.value === 'Desabilitado') {
          strReturn = dataDefault;
        }
      });
    }

    if (strReturn === '') {
      const auxStr = '1;';
      let auxTempMin = '';
      let auxTempMax = '';

      strReturn += auxStr;

      this.temperatureOptions.forEach(itemTemp => {
        if (
          itemTemp.field === 'temperaturaMinima' &&
          itemTemp.simpleValue != null &&
          typeof itemTemp.simpleValue !== 'undefined' &&
          itemTemp.simpleValue !== ''
        ) {
          auxTempMin = itemTemp.simpleValue;
        } else if (
          itemTemp.field === 'temperaturaMinima' &&
          itemTemp.simpleValue == null &&
          typeof itemTemp.simpleValue === 'undefined'
        ) {
          auxTempMin = 'undefined';
        }

        if (
          itemTemp.field === 'temperaturaMaxima' &&
          itemTemp.simpleValue != null &&
          typeof itemTemp.simpleValue !== 'undefined' &&
          itemTemp.simpleValue !== ''
        ) {
          auxTempMax = itemTemp.simpleValue;
        } else if (
          itemTemp.field === 'temperaturaMaxima' &&
          itemTemp.simpleValue == null &&
          typeof itemTemp.simpleValue === 'undefined'
        ) {
          auxTempMax = 'undefined';
        }
      });
      strReturn += `${auxTempMin};`;
      strReturn += auxTempMax;
    }

    return strReturn;
  }

  getBuzzerValue() {
    const dataDefault = 'Desabilitado';

    let strReturn = '';

    if (this.arrayEnabledComboOptions.length > 0) {
      this.arrayEnabledComboOptions.forEach(item => {
        if (item.selected && item.value === 'Desabilitado') {
          strReturn = dataDefault;
        }
      });
    }

    if (strReturn === '') {
      if (this.arrayBuzzerComboOptions.length > 0) {
        this.arrayBuzzerComboOptions.forEach(item => {
          if (item.selected) {
            strReturn += item.value;
          }
        });
      }
    }
    return strReturn;
  }

  validateHasEmptyParams() {
    this.temperatureOptions.forEach(itemTemp => {
      if (
        (itemTemp.field === 'temperaturaMinima' &&
          itemTemp.simpleValue == null &&
          typeof itemTemp.simpleValue !== 'undefined') ||
        (itemTemp.field === 'temperaturaMinima' &&
          itemTemp.simpleValue != null &&
          itemTemp.simpleValue === '')
      ) {
        itemTemp.error = true;
      }

      if (
        (itemTemp.field === 'temperaturaMaxima' &&
          itemTemp.simpleValue == null &&
          typeof itemTemp.simpleValue !== 'undefined') ||
        (itemTemp.field === 'temperaturaMaxima' &&
          itemTemp.simpleValue != null &&
          itemTemp.simpleValue === '')
      ) {
        itemTemp.error = true;
      }
    });
  }

  validateValidContent() {
    let auxTempMin = 0;
    let auxTempMax = 0;

    this.temperatureOptions.forEach(itemTemp => {
      if (
        itemTemp.field === 'temperaturaMinima' &&
        itemTemp.simpleValue != null &&
        typeof itemTemp.simpleValue !== 'undefined'
      ) {
        auxTempMin = itemTemp.simpleValue;
      } else if (
        itemTemp.field === 'temperaturaMinima' &&
        itemTemp.simpleValue == null &&
        typeof itemTemp.simpleValue === 'undefined'
      ) {
        auxTempMin = -9999;
        itemTemp.limitValuesInvalid = true;
      }
      if (
        itemTemp.field === 'temperaturaMaxima' &&
        itemTemp.simpleValue != null &&
        typeof itemTemp.simpleValue !== 'undefined'
      ) {
        auxTempMax = itemTemp.simpleValue;
      } else if (
        itemTemp.field === 'temperaturaMaxima' &&
        itemTemp.simpleValue == null &&
        typeof itemTemp.simpleValue === 'undefined'
      ) {
        auxTempMax = -9999;
        itemTemp.limitValuesInvalid = true;
      }
    });

    if (
      auxTempMin == auxTempMax ||
      auxTempMin > auxTempMax ||
      auxTempMin < this.valorMinimo ||
      auxTempMax > this.valorMaximo
    ) {
      if (auxTempMin !== -9999 && auxTempMax !== -9999) {
        this.temperatureOptions.forEach(itemTemp => {
          if (itemTemp.field === 'temperaturaMinima') {
            itemTemp.limitValuesInvalid = true;
          }
          if (itemTemp.field === 'temperaturaMaxima') {
            itemTemp.limitValuesInvalid = true;
          }
        });
      }
      return false;
    }
    return true;
  }

  _comboOptionsClick() {
    if (
      typeof this.arrayEnabledComboOptions !== 'undefined' &&
      this.arrayEnabledComboOptions.length > 0
    ) {
      this.arrayEnabledComboOptions.forEach(item => {
        if (item.selected && item.value === 'Desabilitado') {
          this.temperatureOptions = [
            {
              objectId: '1',
              field: 'temperaturaMinima',
              label: 'Temperatura Mínima',
              error: false,
              complement: '°C',
              disabled: true,
              size: 'small',
            },
            {
              objectId: '2',
              field: 'temperaturaMaxima',
              label: 'Temperatura Máxima',
              error: false,
              complement: '°C',
              disabled: true,
              size: 'small',
            },
          ];
          this.arrayBuzzerComboOptions = [
            {
              objectId: '1',
              value: 'Desabilitado',
            },
          ];
        } else if (item.selected && item.value === 'Habilitado') {
          this.temperatureOptions.forEach(itemTemp => {
            if (
              itemTemp.field === 'temperaturaMinima' &&
              typeof itemTemp.simpleValue !== 'undefined' &&
              itemTemp.simpleValue != this.temperaturaMinima
            ) {
              this.temperaturaMinima = itemTemp.simpleValue;
            }
            if (
              itemTemp.field === 'temperaturaMaxima' &&
              typeof itemTemp.simpleValue !== 'undefined' &&
              itemTemp.simpleValue != this.temperaturaMaxima
            ) {
              this.temperaturaMaxima = itemTemp.simpleValue;
            }
          });

          if (this.arrayBuzzerComboOptions.length > 1) {
            this.arrayBuzzerComboOptions.forEach(itemTemp2 => {
              if (itemTemp2.selected && itemTemp2.value != this.buzzerValue) {
                this.buzzerValue = itemTemp2.value;
              }
            });
          }

          this.temperatureOptions = [
            {
              objectId: '1',
              field: 'temperaturaMinima',
              label: 'Temperatura Mínima',
              error: false,
              complement: '°C',
              simpleValue: this.temperaturaMinima,
              minValue: this.valorMinimo,
              maxValue: this.valorMaximo,
              allowZero: true,
              disabled: false,
              size: 'small',
              placeholder: '0',
            },
            {
              objectId: '2',
              field: 'temperaturaMaxima',
              label: 'Temperatura Máxima',
              error: false,
              complement: '°C',
              simpleValue: this.temperaturaMaxima,
              minValue: this.valorMinimo,
              maxValue: this.valorMaximo,
              allowZero: true,
              disabled: false,
              size: 'small',
              placeholder: '0',
            },
          ];

          this.arrayBuzzerComboOptions = [
            {
              objectId: '1',
              value: 'Desabilitado',
              selected: this.buzzerValue == 'Desabilitado',
            },
            {
              objectId: '2',
              value: 'Habilitado',
              selected: this.buzzerValue == 'Habilitado',
            },
          ];
        }
      });
    }
  }
}

class GolfleetTemperatureSensor {
  constructor() {
    this.bindings = {
      value: '=?',
    };
    this.template = template;
    this.controller = GolfleetTemperatureSensorController;
  }
}

angular
  .module('golfleet-temperature-sensor', [])
  .component('golfleetTemperatureSensor', new GolfleetTemperatureSensor());
